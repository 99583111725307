import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Avatar, Grid, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import {
  AccessTime,
  AlarmOff,
  HourglassEmptyOutlined,
  ThumbUpAltOutlined,
  ThumbDownAltOutlined
} from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import { SUPPORT_CLIENT_OVERVIEW_QUERY } from './data';
import moment from 'moment';
import StaticIntro from 'components/StaticIntro';
import PageUnavailable from 'components/Messages/PageUnavailable';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';

const SupportPage = () => {
  const { role } = useSelector((state) => state.auth);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY/MM/DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY/MM/DD'));
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));

  const handleMonthChange = (event) => {
    const { value } = event.target;
    const [year, month] = value.split('-');
    const start = moment()
      .year(year)
      .month(month - 1)
      .startOf('month')
      .format('YYYY/MM/DD');
    let end;
    if (moment().format('YYYY-MM') === value) {
      end = moment().format('YYYY/MM/DD');
    } else {
      end = moment()
        .year(year)
        .month(month - 1)
        .endOf('month')
        .format('YYYY/MM/DD');
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedMonth(value);
  };

  const minutesToReadableText = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (remainingMinutes === 0) {
      return `${hours}h`;
    } else {
      return `${hours}h ${remainingMinutes}m`;
    }
  };

  const { loading, error, data } = useQuery(SUPPORT_CLIENT_OVERVIEW_QUERY, {
    variables: {
      type: 'support',
      startDate: startDate,
      endDate: endDate
    },
    skip: role !== 'superAdmin'
  });

  if (role !== 'superAdmin') {
    return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StaticIntro pretitle="Management" title="Support Overview" />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" sx={{ mb: 2 }} style={{ float: 'right', minWidth: '200px' }}>
            <InputLabel id="month-select-label">Select Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={selectedMonth}
              label="Select Month"
              onChange={handleMonthChange}
            >
              {[...Array(24).keys()].map((_, index) => {
                const date = moment().subtract(index, 'months');
                const month = date.format('MM');
                const year = date.year();
                return (
                  <MenuItem key={index} value={`${year}-${month}`}>
                    {date.format('MMMM')} {year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {loading && <LoadingCardWrapper />}
      {error && <PageUnavailable />}

      {data?.projects && (
        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'stretch' }}>
          {[...data.projects]
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(({ title, tasks }, index) => {
              const { allowance } = tasks || {};
              const {
                totalAvailableMinutes,
                totalRemainingMinutes,
                totalSpentMinutes,
                workDaysRemaining,
                capacityRisk,
                capacityRiskMinutes,
                overspentRisk,
                overspentMinutes
              } = allowance || {};

              const details = [
                {
                  label: 'Total Available',
                  value: minutesToReadableText(totalAvailableMinutes),
                  icon: <AccessTime />,
                  color: grey[500]
                },
                {
                  label: 'Time Used',
                  value: minutesToReadableText(totalSpentMinutes),
                  icon: <AlarmOff />,
                  color: grey[500]
                },
                {
                  label: 'Remaining',
                  value: minutesToReadableText(totalRemainingMinutes),
                  icon: <HourglassEmptyOutlined />,
                  color: grey[500]
                },
                {
                  label: 'Over Spent',
                  value: minutesToReadableText(overspentMinutes),
                  icon:
                    overspentMinutes > 0 ? (
                      <ThumbDownAltOutlined style={{ color: red[500] }} />
                    ) : (
                      <ThumbUpAltOutlined style={{ color: green[500] }} />
                    ),
                  color: overspentMinutes > 0 ? red[500] : green[500]
                }
              ];

              const styles = {
                width: '100%'
              };

              if (capacityRisk) {
                styles.backgroundColor = '#351601';
              }

              if (overspentRisk) {
                styles.backgroundColor = '#420000';
              }

              return (
                <Grid item xs={12} md={6} lg={4} key={index} style={{ display: 'flex' }}>
                  <DefaultCardWrapper title={title} sx={styles}>
                    <Grid container spacing={2}>
                      {details.map(({ label, value, icon, color }, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          md={3}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                          }}
                        >
                          <Avatar
                            style={{
                              backgroundColor: 'transparent',
                              border: `1px solid ${grey[500]}`,
                              color,
                              marginBottom: '10px'
                            }}
                          >
                            {icon}
                          </Avatar>
                          <Typography component="h6" variant="h6">
                            {value}
                          </Typography>
                          <Typography component="p" variant="body2" style={{ color: grey[500] }}>
                            {label}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>

                    <Typography
                      component="p"
                      variant="body2"
                      style={{ color: grey[500], marginTop: '24px' }}
                      gutterBottom
                    >
                      There {workDaysRemaining === 1 ? 'is' : 'are'} {workDaysRemaining} working day
                      {workDaysRemaining === 1 ? '' : 's'} remaining in this month
                    </Typography>

                    {capacityRisk && (
                      <>
                        <Typography component="h6" variant="h6" style={{ color: 'red' }}>
                          <strong>We are at risk of not delivering this months support</strong>
                        </Typography>
                        {capacityRiskMinutes > 0 && (
                          <Typography component="p" variant="body2" style={{ color: 'red' }}>
                            We would need an additional{' '}
                            {(capacityRiskMinutes / 60) % 1 === 0
                              ? capacityRiskMinutes / 60
                              : (capacityRiskMinutes / 60).toFixed(2)}{' '}
                            hours to delivery this months support
                          </Typography>
                        )}
                      </>
                    )}

                    {overspentRisk && (
                      <>
                        <Typography component="h6" variant="h6" style={{ color: 'red' }}>
                          <strong>We have over delivered this months support</strong>
                        </Typography>
                        {overspentMinutes > 0 && (
                          <Typography component="p" variant="body2" style={{ color: 'red' }}>
                            We have delivered{' '}
                            {(overspentMinutes / 60) % 1 === 0
                              ? overspentMinutes / 60
                              : (overspentMinutes / 60).toFixed(2)}{' '}
                            hours more than is allowed
                          </Typography>
                        )}
                      </>
                    )}
                  </DefaultCardWrapper>
                </Grid>
              );
            })}
        </Grid>
      )}
    </>
  );
};

export default SupportPage;
