import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { grey, red, green } from '@mui/material/colors';

export const StyledDescription = styled('div')`
  table {
    border-collapse: collapse;
    width: 100%;

    & th,
    & td {
      text-align: left;
      border: 1px solid ${(p) => (p.lightMode ? p.theme.palette.common.black : grey[300])};
      padding: ${(p) => `0 ${p.theme.spacing(2)}`};
    }
  }

  code {
    background: ${(p) => (p.lightMode ? grey[300] : p.theme.palette.common.black)};
    color: ${(p) => (p.lightMode ? p.theme.palette.common.black : grey[300])};
    border-radius: 4px;
    font-size: 0.8em;
    padding: 2px 4px;
  }

  blockquote {
    border-left: 3px solid ${(p) => grey[600]};
    margin: 0;
    padding: 0 ${(p) => p.theme.spacing(3)};

    p {
      margin-top: 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  ol,
  ul {
    li {
      margin-bottom: 4px;

      p {
        margin: 0;
      }
    }
  }
`;

export const StyledChip = styled(Chip)`
  border-color: ${(p) => (p.open === true ? red[700] : green[700])} !important;
  color: ${(p) => (p.open === true ? red[700] : green[700])} !important;
  overflow: hidden;
`;

export const TicketIntro = styled('header')`
  margin-bottom: ${(p) => p.theme.spacing(6)} !important;
  padding-bottom: ${(p) => p.theme.spacing(2)} !important;
`;

export const AttachmentListItem = styled('li')`
  align-items: center;
  display: flex;
  list-style: none;
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const thumbSize = 40;

export const Thumb = styled('div')`
  background: ${(p) => (p.src ? 'url(' + p.src + ') no-repeat scroll center center ' + grey[100] : grey[100])};
  background-size: cover;
  border-radius: 100%;
  color: ${(p) => p.theme.palette.primary.main};
  height: ${thumbSize}px;
  margin-right: ${(p) => p.theme.spacing(2)};
  position: relative;
  overflow: hidden;
  width: ${thumbSize}px;

  .MuiSvgIcon-root {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;
