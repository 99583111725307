import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardActions, CardContent, CardMedia, Popover } from '@mui/material';
import { CallMade, LaptopMac } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;

  .MuiCardActions-root {
    margin-top: auto;
  }
`;

export const StyledCardMedia = styled(CardMedia)`
  height: 0;
  padding-bottom: 50%;
`;

export const StyledCardHeaderIcon = styled(({ lightMode, ...other }) => <div {...other} />)`
  background: ${(p) => (p.lightMode ? grey[300] : p.theme.palette.common.black)};
  height: 0;
  padding-bottom: 50%;
  position: relative;
`;

export const PositionedIcon = styled(LaptopMac)`
  height: auto !important;
  left: 50%;
  opacity: 0.3;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 80px !important;
`;

export const StyledPopover = styled(({ ...other }) => <Popover classes={{ paper: 'paper' }} {...other} />)`
  & .paper {
    padding: 0;
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding: 0 ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(2)} !important;
`;

export const StyledCardActions = styled(CardActions)`
  padding: 0 ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(2)} !important;
`;

export const ExternalLink = styled('a')`
  text-decoration: none;
`;

export const Icon = styled(CallMade)`
  height: 16px !important;
`;
