import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as ROUTES from 'constants/routes';

export const PrivateRoute = ({
  component: Component = null,
  render: Render = null,
  loggedIn,
  userRole,
  routeRole,
  featureFlag,
  ...rest
}) => {
  const { features } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedIn) {
          if (routeRole === 'superAdmin' && userRole !== routeRole) {
            return <Redirect to={{ pathname: ROUTES.DASHBOARD, state: { from: props.location } }} />;
          }

          // Check if featureFlag exists in features
          if (featureFlag) {
            if (features && features.length > 0 && features.includes(featureFlag)) {
              // If featureFlag is in features, allow the user to proceed to the route
              return Render ? Render(props) : Component ? <Component {...props} /> : null;
            } else {
              // If featureFlag is not in features, redirect the user to the dashboard
              return <Redirect to={{ pathname: ROUTES.DASHBOARD, state: { from: props.location } }} />;
            }
          }

          return Render ? Render(props) : Component ? <Component {...props} /> : null;
        } else {
          return <Redirect to={{ pathname: ROUTES.AUTH_SIGN_IN, state: { from: props.location } }} />;
        }
      }}
    />
  );
};
