import { Container, Typography } from '@mui/material';
import AccordionWrapper from 'components/Accordion';

export default function FAQ() {
  const faqs = [
    {
      fields: {
        summary: `How do I contact customer support if I have a question or issue?`,
        description: `You can reach our customer support team by emailing LINK or calling our toll-free number. We&apos;re here to assist you promptly.`
      }
    },
    {
      fields: {
        summary: `Can I return the product if it doesn&apos;t meet my expectations?`,
        description: `Absolutely! We offer a hassle-free return policy. If you&apos;re not completely satisfied, you can return the product within [number of days] days for a full refund or exchange.`
      }
    },
    {
      fields: {
        summary: `What makes your product stand out from others in the market?`,
        description: `Our product distinguishes itself through its adaptability, durability, and innovative features. We prioritize user satisfaction and continually strive to exceed expectations in every aspect.`
      }
    },
    {
      fields: {
        summary: `Is there a warranty on the product, and what does it cover?`,
        description: `Yes, our product comes with a [length of warranty] warranty. It covers defects in materials and workmanship. If you encounter any issues covered by the warranty, please contact our customer support for assistance.`
      }
    }
  ];

  return (
    <Container
      id="faqs"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 }
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' }
        }}
      >
        Frequently asked questions
      </Typography>
      <AccordionWrapper data={faqs} />
    </Container>
  );
}
