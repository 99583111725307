export const startCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const textToLink = (text) => text.replace('<a', '<a target="_blank"');

export const truncate = (text, options) => {
  return text.length > options.length ? text.substring(0, options.length) + '...' : text;
};

export const uniqueId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const remainingSeconds = seconds % 60;

  let duration = '';
  if (hours > 0) duration += `${hours}h `;
  if (minutes > 0) duration += `${minutes}m `;
  if (remainingSeconds > 0) duration += `${remainingSeconds}s`;

  return duration.trim();
};
