import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Select, MenuItem, FormControl, Typography } from '@mui/material';
import { setProjectCount } from 'reducers/authReducer';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';
import ProjectList from 'components/Lists/ProjectList';

const ProjectsListCard = () => {
  const dispatch = useDispatch();
  const { agencies, projects } = useSelector((state) => state.auth);
  const [selectedGroup, setSelectedGroup] = useState('All');
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const allProjects = [
      ...(projects || []).map((project) => ({ ...project, source: 'Personal' })),
      ...(agencies?.flatMap((agency) => agency.projects.map((project) => ({ ...project, source: agency.name }))) || [])
    ].reduce((unique, project) => {
      return unique.some((p) => p.id === project.id) ? unique : [...unique, project];
    }, []);

    if (selectedGroup === 'All') {
      setFilteredProjects(allProjects);
    } else if (selectedGroup === 'Personal') {
      setFilteredProjects(projects || []);
    } else {
      const agency = agencies?.find((agency) => agency.name === selectedGroup);
      setFilteredProjects(agency?.projects || []);
    }
  }, [selectedGroup, projects, agencies, dispatch]);

  useEffect(() => {
    const allProjects = [
      ...(projects || []).map((project) => ({ ...project, source: 'Personal' })),
      ...(agencies?.flatMap((agency) => agency.projects.map((project) => ({ ...project, source: agency.name }))) || [])
    ].reduce((unique, project) => {
      return unique.some((p) => p.id === project.id) ? unique : [...unique, project];
    }, []);

    /** Update projectCount in auth reducer */
    dispatch(setProjectCount(allProjects.length));
  }, [projects, agencies, dispatch]);

  const sources = ['All', ...(agencies?.map((agency) => agency.name) || []), 'Personal'];

  return (
    <DefaultCardWrapper
      title="Your Project"
      header={
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" component="h2">
              <strong>Your Projects</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControl style={{ minWidth: '120px' }}>
              <Select
                id="group-select"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
                size="small"
              >
                {sources.map((group, index) => (
                  <MenuItem value={group} key={index}>
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      }
    >
      <ProjectList projects={filteredProjects} personal={selectedGroup === 'Personal'} list={true} />
    </DefaultCardWrapper>
  );
};

export default ProjectsListCard;
