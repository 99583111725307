import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import { ModalImg } from './styles';

const AttachmentModal = ({ title, image, onCloseModal, openModal }) => (
  <Dialog open={openModal} onClose={onCloseModal} fullWidth={true} maxWidth="lg" aria-labelledby="alert-dialog-title">
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <ModalImg src={image} alt={title} />
    </DialogContent>
    <DialogActions>
      <Button autoFocus variant="outlined" onClick={onCloseModal} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default AttachmentModal;
