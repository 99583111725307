import { useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { CallMade } from '@mui/icons-material';
import { ExternalLink } from './styles';
import PopInHeader from 'components/PopInHeader';

const ProjectIntro = ({ project, setIntro, title }) => {
  useEffect(() => {
    const populateIntro = (wipe) => {
      if (wipe) {
        setIntro(null);
      } else {
        setIntro(
          <PopInHeader position={150}>
            <Typography component="p" variant="body1" color="primary">
              {project.title}
              {project.projectType === 'website' ? (
                <ExternalLink href={project.websiteUrl} target="_blank" rel="noopener noreferrer">
                  <Button endIcon={<CallMade />} variant="outlined" color="primary" size="small">
                    Visit site
                  </Button>
                </ExternalLink>
              ) : null}
            </Typography>
          </PopInHeader>
        );
      }
    };

    populateIntro();

    return function cleanup() {
      populateIntro(true);
    };
  }, [project, setIntro]);

  return (
    <Grid container spacing={4} sx={{ mb: 4 }}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography component="h3" variant="h3">
          <strong>{title}</strong>
        </Typography>

        <Typography component="h6" variant="h6" gutterBottom>
          {project.title}
        </Typography>

        {project.projectType === 'website' ? (
          <ExternalLink href={project.websiteUrl} target="_blank" rel="noopener noreferrer">
            <Button endIcon={<CallMade />} variant="outlined" color="primary" size="small">
              Visit site
            </Button>
          </ExternalLink>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ProjectIntro;
