import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, FormControl } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { validationSchema } from './validation';
import { /*ErrorMessage,*/ IconButtonPosition } from 'components/Forms/RegistrationForm/styles';

const UpdateUserPasswordForm = () => {
  const { lightMode } = useSelector((state) => state.type);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword({ showPassword: !showPassword });
  };

  return (
    <Formik
      initialValues={{
        password1: '',
        password2: ''
      }}
      validationSchema={validationSchema}
      onSubmit={({ password1 }) => {
        console.log('password1', password1);
      }}
    >
      {({ errors, touched, values }) => (
        <Form noValidate>
          <FormControl fullWidth>
            <Field
              component={TextField}
              name="password1"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="password"
              margin="normal"
              required
              fullWidth
              disabled={false}
            />
            <IconButtonPosition
              color={lightMode ? 'primary' : 'secondary'}
              aria-label="Toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButtonPosition>
          </FormControl>

          <Field
            component={TextField}
            name="password2"
            label="Confirm password"
            type="password"
            margin="normal"
            required
            fullWidth
            disabled={false}
            style={{ marginBottom: '30px' }}
          />

          <Button type="submit" size="large" variant="contained" color={lightMode ? 'primary' : 'secondary'}>
            Update Password
          </Button>

          {/* <ErrorMessage component="p">{signupError}</ErrorMessage> */}
        </Form>
      )}
    </Formik>
  );
};

export default UpdateUserPasswordForm;
