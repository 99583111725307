import { Fragment } from 'react';
import { Grid, Typography } from '@mui/material';
import SslCard from 'components/Cards/SslCard';
import ProjectMonitoringCard from 'components/Cards/ProjectMonitoringCard';
import ProjectIncidentsCard from 'components/Cards/ProjectIncidentsCard';
import EnvironmentsCard from 'components/Cards/EnvironmentsCard';

const HostingPage = ({ project }) => (
  <Grid container spacing={4}>
    <Grid item xs={12} md={6}>
      <Typography component="h4" variant="h4" sx={{ mb: 2 }}>
        <strong>Hosting</strong>
      </Typography>

      <Typography component="p" variant="body2" gutterBottom sx={{ mb: 4 }}>
        This page provides information about the environments, hosting and monitoring of the project.
      </Typography>

      <SslCard ssl={project.ssl} support={project.supportClient} />

      <EnvironmentsCard servers={project?.servers} />
    </Grid>

    <Grid item xs={12} md={6}>
      {project.monitors?.map((monitor, index) => (
        <Fragment key={index}>
          <ProjectMonitoringCard monitor={monitor} index={index} />
          {monitor.logs?.length > 0 && <ProjectIncidentsCard logs={monitor.logs} />}
        </Fragment>
      ))}
    </Grid>
  </Grid>
);

export default HostingPage;
