import { useState } from 'react';
import { Avatar, Grid, List, ListItem, ListItemText, ListItemIcon, Popover, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ProjectCard from 'components/Cards/ProjectCard';
import PropTypes from 'prop-types';
import * as ROUTES from 'constants/routes';

const GridContainer = ({ projects, list }) => {
  const [anchorEls, setAnchorEls] = useState([]);

  const handlePopoverOpen = (event, index) => {
    const newAnchorEls = Array(anchorEls.length).fill(null);
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handlePopoverClose = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  return (
    <Grid container spacing={4}>
      {projects &&
        projects.length > 0 &&
        [...projects]
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((project, index) => {
            if (list) {
              const { id, title, websiteUrl, screenshot } = project;
              const open = Boolean(anchorEls[index]);

              const image = screenshot.secure_url
                ? screenshot.secure_url.replace(/(\/upload\/)(v\d+\/)/, '$1c_fill,h_80,w_80/$2')
                : '';
              const imageLarge = screenshot.secure_url
                ? screenshot.secure_url.replace(/(\/upload\/)(v\d+\/)/, '$1c_fill,w_300/$2')
                : '';

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ListItem button component={Link} to={`${ROUTES.DASHBOARD_PROJECT_ROOT}/${id}`} key={index}>
                    <ListItemIcon
                      onMouseEnter={(event) => handlePopoverOpen(event, index)}
                      onMouseLeave={() => handlePopoverClose(index)}
                    >
                      {screenshot?.url ? (
                        <Avatar alt={title}>{title.substring(0, 1)}</Avatar>
                      ) : (
                        <Avatar alt={title} src={image} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={title} secondary={websiteUrl ? websiteUrl : null} />
                  </ListItem>
                  <Popover
                    id={`mouse-over-popover-${index}`}
                    sx={{
                      pointerEvents: 'none'
                    }}
                    open={open}
                    anchorEl={anchorEls[index]}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    onMouseLeave={() => handlePopoverClose(index)}
                    onClose={() => handlePopoverClose(index)}
                    disableRestoreFocus
                  >
                    <img src={imageLarge} alt={title} style={{ width: '300px', height: 'auto' }} loading="lazy" />
                  </Popover>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} md={6} lg={3} xl={2} key={index}>
                  <ProjectCard project={project} />
                </Grid>
              );
            }
          })}
    </Grid>
  );
};

const ProjectList = ({ projects, list = false }) => {
  return !projects || projects.length === 0 ? (
    <Typography gutterBottom>
      <strong>You have no projects</strong>
    </Typography>
  ) : (
    <>
      {list ? (
        <List>
          <GridContainer projects={projects} list={list} />
        </List>
      ) : (
        <GridContainer projects={projects} list={list} />
      )}
    </>
  );
};

ProjectList.propTypes = {
  projects: PropTypes.array,
  list: PropTypes.bool
};

export default ProjectList;
