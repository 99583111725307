import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FormHeader from 'components/FormHeader';
import LoginForm from 'components/Forms/LoginForm';
import * as ROUTES from 'constants/routes';

const SignInPage = () => {
  const { loggedIn } = useSelector((state) => state.auth);

  if (loggedIn) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  return (
    <>
      <FormHeader
        forceColor="dark"
        lightState={false}
        title="Login"
        subtitle="Welcome. Please login to your account."
      />
      <LoginForm lightState={false} />
    </>
  );
};

export default SignInPage;
