import { styled } from '@mui/material/styles';
import { Button, DialogTitle } from '@mui/material';
import { red } from '@mui/material/colors';

export const IconButton = styled(Button)`
  svg {
    margin-right: 6px;
  }
`;

export const DeleteIconButton = styled(Button)`
  background-color: ${red[700]} !important;
  margin-right: ${(p) => p.theme.spacing(2)} !important;
  color: #ffffff !important;

  &.Mui-disabled {
    opacity: 0.5;
  }
`;

export const InlineCode = styled('code')`
  background-color: ${(p) => p.theme.palette.grey[300]};
  border-radius: 4px;
  color: ${(p) => p.theme.palette.grey[700]};
  padding: 2px 6px;
  user-select: all;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  text-align: center;
`;

export const CenterContent = styled('div')`
  text-align: center;
`;
