import { useSelector } from 'react-redux';
import SupportSidebar from 'views/Dashboard/Support/SupportSidebar';
import TaskPage from 'views/Dashboard/Tasks/TaskPage';

const SupportTicketPage = ({ extraSidebar }) => {
  const {
    support: { openCount, closedCount }
  } = useSelector((state) => state.auth);

  return (
    <>
      <SupportSidebar openCount={openCount} closedCount={closedCount} />
      <TaskPage />
    </>
  );
};

export default SupportTicketPage;
