import { useSelector } from 'react-redux';
import { Chip, Divider, Grid, IconButton, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { chipColor, chipIcon } from 'helpers/chip';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';

const ProfitLossChip = ({ totalTimeSpent, project }) => {
  if (project.fields.status.statusCategory.name === 'Done') {
    return totalTimeSpent < project.fields.timeestimate ? (
      <Chip label="Profit Made" color="success" />
    ) : (
      <Chip label="Loss Made" color="error" />
    );
  }
  return <Chip label="Being Worked On" color="warning" />;
};

const SubProjects = ({ relatedProjects }) => {
  const { role } = useSelector((state) => state.auth);

  if (!relatedProjects || relatedProjects.length === 0) {
    return null;
  }

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const hoursDisplay = hours > 0 ? `${hours}h ` : '';
    const minutesDisplay = minutes > 0 ? `${minutes}m ` : '';
    const secondsDisplay = secs > 0 ? `${secs}s` : '';

    return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`.trim();
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Typography component="h2" variant="h2">
            <strong>Related Projects</strong>
          </Typography>
          <Typography component="p">
            Below are any micro-projects that are related to this project but not part of the continuous improvements
            contract. This may include projects that are part of the same codebase, but are being billed separately.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        {relatedProjects.map((project, index) => {
          // Calculate total time spent for each project
          const totalTimeSpent = project.tasks.reduce((total, task) => {
            const timeSpent = task.fields.timespent;
            return total + (typeof timeSpent === 'number' && !isNaN(timeSpent) ? timeSpent : 0);
          }, 0);

          // Format the total time spent
          const formattedTotalTimeSpent = formatTime(totalTimeSpent);

          return (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <DefaultCardWrapper
                header={
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6" component="h2">
                      <strong>{project.fields.summary}</strong>
                    </Typography>
                    <Chip
                      variant="outlined"
                      label={project.fields.status.statusCategory.name}
                      color={chipColor(project.fields.status.statusCategory.name)}
                    />
                  </Stack>
                }
              >
                <List dense={true}>
                  {project.tasks.map((task, index) => (
                    <ListItem key={index} sx={{ padding: role === 'superAdmin' ? '4px 0' : '4px 0 12px' }}>
                      <ListItemText
                        primary={task.fields.summary}
                        secondary={
                          role === 'superAdmin' && (
                            <>
                              {Object.keys(task.fields.timeestimate).length !== 0 ||
                                (typeof task.fields.timeestimate !== 'object' &&
                                  `Estimate: ${formatTime(task.fields.timeestimate)}`)}
                              {Object.keys(task.fields.timespent).length !== 0 ||
                                (typeof task.fields.timespent !== 'object' &&
                                  ` | Time Spent: ${formatTime(task.fields.timespent)}`)}
                            </>
                          )
                        }
                      />

                      <Chip
                        variant="outlined"
                        label={task.fields.status.statusCategory.name}
                        icon={chipIcon(task.fields.status.statusCategory.name)}
                        color={chipColor(task.fields.status.statusCategory.name)}
                      />

                      {role === 'superAdmin' && (
                        <IconButton
                          sx={{ ml: 1 }}
                          size="small"
                          component="a"
                          href={`https://andanotherday.atlassian.net/browse/${task.key}`}
                          target="_blank"
                          aria-label="Open in Jira"
                        >
                          <OpenInNew />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}

                  {role === 'superAdmin' && (
                    <>
                      <Divider sx={{ margin: '4px 0 8px' }} />

                      <ListItem sx={{ padding: '4px 0' }}>
                        <ListItemText>
                          <strong>Time Estimate:</strong> {formatTime(project.fields.timeestimate)}
                          <br />
                          <strong>Time Spent:</strong> {formattedTotalTimeSpent || '0'}
                        </ListItemText>

                        <ProfitLossChip totalTimeSpent={totalTimeSpent} project={project} />
                      </ListItem>
                    </>
                  )}
                </List>
              </DefaultCardWrapper>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SubProjects;
