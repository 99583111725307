import moment from 'moment';
import { useState } from 'react';
import { Divider, Grid, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { grey, green, red } from '@mui/material/colors';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';

const EnvironmentsCard = ({ servers }) => {
  const [selectedEnvironment, setSelectedEnvironment] = useState('all');

  const environments = [];

  servers?.forEach((server) => {
    if (!environments.some((env) => env.environment === server.environment)) {
      environments.push({ environment: server.environment, servers: [] });
    }
  });

  /** For each server push into the relevant environment object in the servers array */
  servers?.forEach((server) => {
    environments.forEach((env) => {
      if (env.environment === server.environment) {
        env.servers.push(server);
      }
    });
  });

  const filteredServers = environments.filter(
    (environment) => environment.environment === selectedEnvironment || selectedEnvironment === 'all'
  );

  return (
    <>
      <Grid container spacing={4} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography component="h4" variant="h4">
            <strong>Environments</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormControl style={{ minWidth: '120px' }}>
            <InputLabel id="environment-select-label">Environment</InputLabel>
            <Select
              labelId="environment-select-label"
              id="environment-select"
              value={selectedEnvironment}
              onChange={(e) => setSelectedEnvironment(e.target.value)}
              label="Environment"
            >
              <MenuItem value="all">All</MenuItem>
              {environments.map(({ environment }, index) => (
                <MenuItem value={environment} key={index}>
                  {environment.charAt(0).toUpperCase() + environment.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {filteredServers.map(({ environment, servers }, index) => {
        return (
          <DefaultCardWrapper
            key={index}
            title={`${environment.charAt(0).toUpperCase()}${environment.slice(1)} Sites`}
            sx={{ mb: 4 }}
          >
            {servers.map(({ name, url, deployments }, index) => (
              <div key={index}>
                <Typography component="p" variant="body1" sx={{ mb: 1 }}>
                  {name}
                </Typography>

                <Typography component="p" variant="body2" sx={{ color: grey[500] }}>
                  {url}
                </Typography>

                {deployments.records.length > 0 && (
                  <>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: deployments.records[0].status === 'completed' ? green[500] : red[500] }}
                    >
                      Status: {deployments.records[0].status}
                    </Typography>
                    <Typography variant="subtitle1">Branch: {deployments.records[0].branch}</Typography>

                    {deployments.records[0].deployer && (
                      <Typography variant="subtitle1">Deployed by: {deployments.records[0].deployer}</Typography>
                    )}

                    <Typography variant="subtitle1">
                      Last deployed at{' '}
                      {moment(deployments.records[0].timestamps.started_at).format('MMMM Do YYYY, h:mm:ss a')} and took{' '}
                      {deployments.records[0].timestamps.duration}s
                    </Typography>

                    {index !== servers.length - 1 && <Divider sx={{ mt: 2, mb: 2 }} />}
                  </>
                )}
              </div>
            ))}
          </DefaultCardWrapper>
        );
      })}
    </>
  );
};

export default EnvironmentsCard;
