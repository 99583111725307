import PropTypes from 'prop-types';
import { StyledPaper, StyledPaperInner } from './styles';
import SupportTicketForm from 'components/Forms/SupportTicketForm';

const SupportFormCard = ({ align, single }) => (
  <StyledPaper>
    <StyledPaperInner>
      <SupportTicketForm align={align} single={single} />
    </StyledPaperInner>
  </StyledPaper>
);

SupportFormCard.propTypes = {
  single: PropTypes.string,
  align: PropTypes.string
};

export default SupportFormCard;
