import { useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { PROJECT_QUERY } from './data';
import { Route, Switch, useParams } from 'react-router-dom';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import ProjectIntro from 'components/Intros/ProjectIntro';
import PageUnavailable from 'components/Messages/PageUnavailable';
import ProjectFlow from 'components/Steppers/ProjectFlow';
import OverviewPage from './OverviewPage';
import PlanningPage from './PlanningPage';
import HostingPage from './HostingPage';
import ContinuousImprovementsPage from './ContinuousImprovementsPage';
import * as ROUTES from 'constants/routes';

const RouteComponent = ({ id, project, componentName, component, setIntro, title }) => (
  <>
    <ProjectIntro title={title} project={project} setIntro={setIntro} />
    <ProjectFlow id={id} componentName={componentName} />
    {component}
  </>
);

const Project = ({ route, setIntro }) => {
  const { id } = useParams();
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY/MM/DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY/MM/DD'));
  const [selectedMonth, setSelectedMonth] = useState('');
  const [showOpen, setShowOpen] = useState(true);
  const [months, setMonths] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const { loading: queryLoading, error, data } = useQuery(PROJECT_QUERY, {
    variables: { id, startDate, endDate, showOpen },
    onCompleted: () => setFirstLoad(false)
  });

  useEffect(() => {
    if (!queryLoading) {
      setProjectData(data); // Update table data when query data changes
      setLoadingTable(false); // Set loading state of table to false
    }
  }, [data, queryLoading]);

  if (firstLoad && queryLoading) return <LoadingCardWrapper page={true} />;
  if (error) return <PageUnavailable message="Unable to load project. Please try again later." />;

  const { project } = projectData || {};

  const projectPath = `${ROUTES.DASHBOARD_PROJECT_ROOT}/${id}`;

  const routes = [
    {
      title: 'Planning',
      path: `${projectPath}${ROUTES.DASHBOARD_PROJECT_PLANNING}`,
      componentName: ROUTES.DASHBOARD_PROJECT_PLANNING,
      component: <PlanningPage project={project} />
    },
    {
      title: 'Hosting',
      path: `${projectPath}${ROUTES.DASHBOARD_PROJECT_HOSTING}`,
      componentName: ROUTES.DASHBOARD_PROJECT_HOSTING,
      component: <HostingPage project={project} />
    },
    {
      title: 'Continuous Improvements',
      path: `${projectPath}${ROUTES.DASHBOARD_PROJECT_CI}`,
      componentName: ROUTES.DASHBOARD_PROJECT_CI,
      component: (
        <ContinuousImprovementsPage
          id={id}
          projectData={projectData}
          error={error}
          loadingTable={loadingTable}
          setLoadingTable={setLoadingTable}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          months={months}
          setMonths={setMonths}
          selectedMonth={selectedMonth}
          setShowOpen={setShowOpen}
          setSelectedMonth={setSelectedMonth}
          relatedProjects={project?.relatedProjects}
        />
      )
    },
    {
      title: 'Overview',
      path: `${projectPath}/`,
      componentName: `/`,
      component: <OverviewPage project={project} />
    }
  ];

  return project ? (
    <Route
      path="/"
      render={({ location }) => (
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              render={() => (
                <RouteComponent
                  id={id}
                  setIntro={setIntro}
                  project={project}
                  componentName={route.componentName}
                  component={route.component}
                  title={route.title}
                />
              )}
            />
          ))}
        </Switch>
      )}
    />
  ) : null;
};

export default Project;
