import PropTypes from 'prop-types';
import { Root, Main, MainInner } from './styles';
import { useSelector } from 'react-redux';
import Header from 'components/Header';
import DashboardMenu from 'components/Menus/DashboardMenu';
import DashboardFooter from 'components/Footer/DashboardFooter';
import VerifyEmail from 'views/Auth/VerifyEmail';

const DashboardTemplate = ({ component: Component, route, extraSidebar, intro, setIntro }) => {
  const { open } = useSelector((state) => state.menu);

  return (
    <Root>
      <DashboardMenu />
      <Main extraSidebar={extraSidebar} open={open}>
        <Header route={route} intro={intro} />

        <VerifyEmail extraSidebar={extraSidebar} />

        <MainInner extraSidebar={extraSidebar}>
          <Component route={route} extraSidebar={extraSidebar} setIntro={setIntro} />
        </MainInner>

        <DashboardFooter />
      </Main>
    </Root>
  );
};

/** Type checking */
DashboardTemplate.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  route: PropTypes.object.isRequired,
  extraSidebar: PropTypes.bool
};

export default DashboardTemplate;
