import { Typography, Divider } from '@mui/material';
import { StyledPaper, StyledPaperInner } from './styles';

const DefaultCardWrapper = ({ title, header = null, children, sx }) => (
  <StyledPaper sx={sx}>
    <StyledPaperInner>
      {header ? (
        header
      ) : (
        <Typography variant="h6" component="h2">
          <strong>{title}</strong>
        </Typography>
      )}
    </StyledPaperInner>
    <Divider />
    <StyledPaperInner>{children}</StyledPaperInner>
  </StyledPaper>
);

export default DefaultCardWrapper;
