import { useSelector } from 'react-redux';
import { FormHeaderWrapper, FormTitle, FormSubtitle } from './styles';

const FormHeader = ({ title, subtitle, align, forceColor }) => {
  const lightMode = useSelector((state) => state.type.lightMode);

  return (
    <FormHeaderWrapper align={align}>
      <FormTitle component="h1" variant="h5" forceColor={forceColor} lightState={lightMode}>
        {title}
      </FormTitle>
      <FormSubtitle component="h2" variant="subtitle1">
        {subtitle}
      </FormSubtitle>
    </FormHeaderWrapper>
  );
};

export default FormHeader;
