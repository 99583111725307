import React, { Fragment, useState } from 'react';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { RocketLaunch, GitHub, KeyboardArrowRight } from '@mui/icons-material';
import ExtraRowDetails from '../ExtraRowDetails';

const DeploymentTable = ({ project, index }) => {
  const [open, setOpen] = useState({});

  const {
    permalink,
    repository: {
      repo_url,
      hosting_service: { commits_url }
    },
    groups
  } = project;

  const handleOpen = (i) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [i]: !prevOpen[i]
    }));
  };

  const handleClose = (i) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [i]: false
    }));
  };

  return groups.length > 0 ? (
    groups.map((group, groupIndex) => (
      <Table
        key={`group-${groupIndex}-${group.environment}`}
        sx={{
          '&': {
            borderRadius: '4px',
            background: '#1d1d1d',
            marginBottom: '16px'
          },
          '&:last-child': {
            marginBottom: '0'
          }
        }}
      >
        <TableHead
          sx={{
            '& td, & th': {
              borderBottom: '0',
              paddingBottom: '0'
            }
          }}
        >
          <TableRow>
            <TableCell variant="head" sx={{ width: '40%' }}>
              <Typography component="h6" variant="h6">
                <strong>{group.name}</strong>
              </Typography>
            </TableCell>
            <TableCell variant="head" sx={{ width: '40%' }}></TableCell>
            <TableCell variant="head" sx={{ width: '20%' }}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody key={`${index}-${groupIndex}`}>
          {group.servers.length === 0 ? (
            <TableRow
              sx={{
                '& td, & th': {
                  borderBottom: '0'
                }
              }}
            >
              <TableCell colSpan={3}>Please check configuration in DeployHQ</TableCell>
            </TableRow>
          ) : (
            <>
              {group.servers.map(({ name, branch, last_revision, deployments }, serverIndex) => (
                <Fragment key={`${index}-${groupIndex}-${serverIndex}`}>
                  <TableRow
                    sx={{
                      '& td, & th': {
                        borderBottom: '0',
                        paddingBottom: '8px',
                        paddingTop: '8px'
                      },
                      '&:last-child td, &:last-child th': {
                        paddingBottom: '16px'
                      }
                    }}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      {branch === '' ? (
                        <Chip
                          label={last_revision}
                          clickable
                          color="primary"
                          component="a"
                          target="_blank"
                          href={`${commits_url.replace('main', '').replace('master', '')}${last_revision}`}
                        />
                      ) : (
                        <Chip label={branch} color={branch === 'master' || branch === 'main' ? 'success' : 'warning'} />
                      )}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Tooltip describeChild title="View Project in DeployHQ" placement="top" arrow>
                          <IconButton
                            size="small"
                            target="_blank"
                            href={`https://andanotherday.deployhq.com/projects/${permalink}/overview`}
                          >
                            <RocketLaunch />
                          </IconButton>
                        </Tooltip>

                        <Tooltip describeChild title="View Project in Github" placement="top" arrow>
                          <IconButton size="small" target="_blank" href={repo_url}>
                            <GitHub />
                          </IconButton>
                        </Tooltip>

                        <Tooltip describeChild title="More Details" placement="top" arrow>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleOpen(`${groupIndex}-${serverIndex}`)}
                          >
                            <KeyboardArrowRight />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>

                  <ExtraRowDetails
                    deployments={deployments}
                    open={open[`${groupIndex}-${serverIndex}`] || false}
                    onClose={() => handleClose(`${groupIndex}-${serverIndex}`)}
                  />
                </Fragment>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    ))
  ) : (
    <Typography variant="body2">No server groups found. Please check deployments configuration</Typography>
  );
};

export default DeploymentTable;
