import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from 'reducers/authReducer';
import typeReducer from 'reducers/typeReducer';
import errorReducer from 'reducers/errorReducer';
import menuReducer from 'reducers/menuReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['errors']
};

const rootReducer = combineReducers({
  auth: authReducer,
  type: typeReducer,
  errors: errorReducer,
  menu: menuReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: process.env.REACT_APP_NODE_ENV !== 'production'
});

export default store;
export const persistor = persistStore(store);
