import { gql } from '@apollo/client';

export const FORECASTING_QUERY = gql`
  query GetReleases {
    forecasting {
      releases {
        name
        releaseDate
        released
        invoice {
          date
          dueDate
          invoiceID
          currencyCode
          amountPaid
          amountDue
          contact {
            name
            website
          }
        }
      }
    }
  }
`;
