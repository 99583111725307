import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, Stepper, Step, StepLabel } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { projectSteps } from 'views/Dashboard/Project/data';
import * as ROUTES from 'constants/routes';

const ColorlibConnector = styled(StepConnector)`
  &.${stepConnectorClasses.alternativeLabel} {
    top: 22px;
  }

  &.${stepConnectorClasses.active} {
    & .${stepConnectorClasses.line} {
      background-image: linear-gradient(95deg, rgb(242, 113, 33) 0%, rgb(233, 64, 87) 50%, rgb(138, 35, 135) 100%);
    }
  }

  &.${stepConnectorClasses.completed} {
    & .${stepConnectorClasses.line} {
      background-image: linear-gradient(95deg, rgb(242, 113, 33) 0%, rgb(233, 64, 87) 50%, rgb(138, 35, 135) 100%);
    }
  }

  & .${stepConnectorClasses.line} {
    height: 3px;
    border: 0;
    background-color: ${(p) => (p.theme.palette.mode === 'dark' ? p.theme.palette.grey[800] : '#eaeaf0')};
    border-radius: 1px;
  }
`;

const ColorlibStepIconRoot = styled(({ theme, ownerState, ...other }) => <div {...other} />)`
  background-color: ${(p) => (p.theme.palette.mode === 'dark' ? p.theme.palette.grey[700] : '#ccc')};
  z-index: 1;
  color: #fff;
  width: 50px;
  cursor: pointer;
  height: 50px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  ${(p) =>
    p.ownerState.active &&
    `
    background-image: linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);
    box-shadow: 0 4px 10px 0 rgba(0,0,0,.25);
  `};

  ${(p) =>
    p.ownerState.completed &&
    `
    background-image: linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);
  `};
`;

const StyledStep = styled(Step)`
  text-decoration: none;
`;

function ColorlibStepIcon({ active, completed, className }, icon) {
  const Icon = icon;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      <Icon />
    </ColorlibStepIconRoot>
  );
}

export default function ProjectFlow({ id, componentName }) {
  const projectPath = `${ROUTES.DASHBOARD_PROJECT_ROOT}/${id}`;
  const activeStepIndex = projectSteps.findIndex(({ route }) => route === `${componentName}`);

  return (
    <Stack sx={{ width: '100%', mb: 5 }} spacing={4}>
      <Stepper alternativeLabel activeStep={activeStepIndex} connector={<ColorlibConnector />}>
        {projectSteps.map(({ label, route, icon }, i) => (
          <StyledStep key={i} component={Link} to={`${projectPath}${route}`}>
            <StepLabel StepIconComponent={(p) => ColorlibStepIcon(p, icon)}>{label}</StepLabel>
          </StyledStep>
        ))}
      </Stepper>
    </Stack>
  );
}
