import React from 'react';
import { styled } from '@mui/material/styles';
import { SnackbarContent } from '@mui/material';
import { amber } from '@mui/material/colors';

export const WarningSnackBar = styled(({ extraSidebar, ...other }) => <SnackbarContent {...other} />)`
  background: ${amber[700]} !important;
  border: none !important;
  color: ${(p) => p.theme.palette.common.black};
  margin-bottom: ${(p) => p.theme.spacing(2)} !important;
  margin-left: ${(p) => (p.extraSidebar ? 245 : 0)} !important;
`;
