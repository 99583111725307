import { gql } from '@apollo/client';

export const LIST_ALL_PROJECTS_QUERY = gql`
  query projects($type: String) {
    projects(type: $type) {
      id
      title
      websiteUrl
      projectType
      projectCode
      ssl {
        valid
        message
      }
      screenshot {
        secure_url
      }
    }
  }
`;
