import { useState } from 'react';
import { TextField, Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import { DEPLOYMENTS_QUERY } from './data';
import DeploymentCards from './DeploymentCards';
import PageUnavailable from 'components/Messages/PageUnavailable';
import StaticIntro from 'components/StaticIntro';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';

const DeploymentsPage = () => {
  const { loading, error, data } = useQuery(DEPLOYMENTS_QUERY);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredProjects = data
    ? data.deploymentProjects.filter((project) => {
        const projectNameMatches = project.name.toLowerCase().includes(filter.toLowerCase());
        const serverNameMatches = project.groups.some((group) =>
          group.servers.some((server) => server.name.toLowerCase().includes(filter.toLowerCase()))
        );
        return projectNameMatches || serverNameMatches;
      })
    : [];

  return (
    <>
      <StaticIntro pretitle="Management" title="Deployments" />
      <Box mb={2}>
        <TextField
          label="Filter by Project Name"
          variant="outlined"
          fullWidth
          value={filter}
          onChange={handleFilterChange}
        />
      </Box>
      {loading && <LoadingCardWrapper />}
      {error && <PageUnavailable />}
      {data && <DeploymentCards projects={filteredProjects} />}
    </>
  );
};

export default DeploymentsPage;
