import moment from 'moment';
import Table from 'components/Table';
import { CheckCircleOutline, CancelOutlined } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';

const ProjectIncidentsCard = ({ logs }) => {
  const logsWithStatus = logs.map((log, index, array) => {
    const nextLog = array[index + 1];
    const status = nextLog && nextLog.type_name === 'up' ? 'Resolved' : 'Open';
    return { ...log, status };
  });

  const downLogsWithStatus = logsWithStatus.filter((log) => log.type_name === 'down');

  return (
    <Table
      title={`Latest Incidents`}
      columns={[
        { title: 'Status', field: 'status' },
        { title: 'Reason', field: 'reason' },
        {
          title: 'Started',
          field: 'dateStarted'
        },
        {
          title: 'Duration',
          field: 'duration'
        }
      ]}
      data={downLogsWithStatus.map((log, index, array) => {
        return {
          status:
            log.status === 'Open' ? (
              <span style={{ color: red[500] }}>
                <CancelOutlined style={{ position: 'relative', top: '6px' }} /> {log.status}
              </span>
            ) : (
              <span style={{ color: green[500] }}>
                <CheckCircleOutline style={{ position: 'relative', top: '6px' }} /> {log.status}
              </span>
            ),
          reason: log.reason.detail,
          dateStarted: moment.unix(log.datetime).utc().format('MMM D, YYYY, hh:mmA [GMT]Z'),
          duration: `${log.duration} ms`
        };
      })}
      localization={{
        body: {
          emptyDataSourceMessage: 'There are currently logs for this project'
        }
      }}
      options={{
        pageSize: 5,
        sorting: false,
        draggable: false,
        filtering: false,
        search: false
      }}
    />
  );
};

export default ProjectIncidentsCard;
