import { Card, CardContent, Typography } from '@mui/material';
import AvatarGenerator from 'components/AvatarGenerator';

const ProjectLeadCard = ({ projectLead }) => {
  return (
    <Card variant="outlined" sx={{ mb: 4 }}>
      <CardContent sx={{ textAlign: 'center', paddingTop: '28px' }}>
        <AvatarGenerator
          email={projectLead.email}
          size={50}
          firstname={projectLead.firstname}
          lastname={projectLead.lastname}
          style={{ margin: '0 auto 6px' }}
        />
        {projectLead.jobTitle && (
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            {projectLead.jobTitle.toUpperCase()}
          </Typography>
        )}

        <Typography component="p" variant="body" gutterBottom>
          {projectLead.firstname} {projectLead.lastname}
        </Typography>

        {projectLead.email && (
          <Typography component="p" variant="body2" gutterBottom>
            <a style={{ textDecoration: 'none' }} href={`mailto:${projectLead.email}`} rel="noopener noreferrer">
              {projectLead.email}
            </a>
          </Typography>
        )}

        {projectLead.phone && (
          <Typography component="p" variant="body2" gutterBottom>
            <a style={{ textDecoration: 'none' }} href={`tel:${projectLead.phone}`} rel="noopener noreferrer">
              {projectLead.phone}
            </a>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ProjectLeadCard;
