import { gql } from '@apollo/client';

export const SUPPORT_CLIENT_OVERVIEW_QUERY = gql`
  query projects($type: String, $startDate: String, $endDate: String) {
    projects(type: $type) {
      id
      title
      tasks(startDate: $startDate, endDate: $endDate) {
        allowance {
          hourlyRateForTimePeriod
          totalAvailableMinutes
          totalRemainingMinutes
          totalSpentMinutes
          workDaysRemaining
          workDaysRemainingMinutes
          capacityRisk
          capacityRiskPercentage
          capacityRiskMinutes
          overspentRisk
          overspentMinutes
          overspentRiskPercentage
        }
      }
    }
  }
`;
