import { gql } from '@apollo/client';

export const DEPLOYMENTS_QUERY = gql`
  {
    deploymentProjects {
      permalink
      name
      repository {
        repo_url
        hosting_service {
          commits_url
        }
      }
      groups {
        environment
        name
        servers {
          name
          branch
          last_revision
          deployments {
            pagination {
              current_page
              offset
              total_pages
              total_records
            }
            records {
              branch
              deployer
              deployer_avatar
              deployment_url
              status
              timestamps {
                started_at
                duration
                completed_at
              }
            }
          }
        }
      }
    }
  }
`;
