import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { GET_AGENCY_QUERY } from './data';
import StaticIntro from 'components/StaticIntro';
import AgencyDetailsTable from './AgencyDetailsTable';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import * as ROUTES from 'constants/routes';

const AgencyDetailsPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_AGENCY_QUERY, { variables: { id } });

  return (
    <>
      <StaticIntro pretitle="Management" title="Agency Overview" />

      {loading && <LoadingCardWrapper page={true} />}

      {error && (
        <>
          <Typography gutterBottom>
            We have encountered an issue trying to fetch this project. Please return to the project list.
          </Typography>
          <Button component={Link} to={ROUTES.DASHBOARD_SUPER_ADMIN_PROJECTS} variant="contained" color="primary">
            Go back to project list
          </Button>
        </>
      )}

      {data && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={8}>
            <AgencyDetailsTable name={data.agency.name} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AgencyDetailsPage;
