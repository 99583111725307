import { useState } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { uniqueId } from 'helpers/content';
import { stateToHTML } from 'draft-js-export-html';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '@mui/material';
import { validationSchema } from './validation';
import { CommentFormWrapper } from './styles';
import { CREATE_TICKET_COMMENT_MUTATION } from './data';
import DropZone from 'components/Attachments/DropZone';

const AddCommentToTicket = ({ ticketId }) => {
  /** Comment textarea */
  const [initialComment, setInitialComment] = useState({
    key: uniqueId(),
    value: ''
  });

  /** Dropzone Attachments */
  const [attachments, setAttachments] = useState([]);
  const removeAllAttachments = () => {
    setAttachments([]);
  };

  const [addSupportTicketComment, { loading, error, data }] = useMutation(CREATE_TICKET_COMMENT_MUTATION, {
    refetchQueries: ['getSupportTicket'],
    onCompleted: (data) => {
      /** Reset the comment textarea */
      setInitialComment({
        key: uniqueId(),
        value: ''
      });

      /** Reset the attachments */
      removeAllAttachments();
    }
  });

  return (
    <Formik
      initialValues={{
        comment: ''
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const { comment, files } = values;

        await addSupportTicketComment({
          variables: { ticketId, comment, files }
        });
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <CommentFormWrapper>
          <Form noValidate>
            <MUIRichTextEditor
              label="Your message"
              key={initialComment.key}
              value={initialComment.value}
              onChange={(state) => setFieldValue('comment', stateToHTML(state.getCurrentContent()))}
              toolbar={true}
              controls={[
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'undo',
                'redo',
                'link',
                'numberList',
                'bulletList',
                'quote'
              ]}
            />

            <DropZone setFieldValue={setFieldValue} attachments={attachments} setAttachments={setAttachments} />

            <div style={{ textAlign: 'right' }}>
              <Button type="submit" variant="contained" color="primary">
                Add Message
              </Button>
            </div>
          </Form>

          {loading && <div>Loading...</div>}
          {error && <div>There's appears to be an error. Please try again later.</div>}
          {data && <div>Your comment has been submitted successfully.</div>}
        </CommentFormWrapper>
      )}
    </Formik>
  );
};

export default AddCommentToTicket;
