import { gql } from '@apollo/client';

export const LIST_ALL_USERS_QUERY = gql`
  {
    users {
      uid
      email
      verified
      role
      firstname
      lastname
      projectCount
    }
  }
`;
