import FormHeader from 'components/FormHeader';
import RegistrationForm from 'components/Forms/RegistrationForm';

const SignUpPage = () => (
  <>
    <FormHeader forceColor="dark" title="Register" subtitle="Please complete to create your account" />
    <RegistrationForm />
  </>
);

export default SignUpPage;
