import BookWoff2 from 'assets/fonts/aad-brother-1816-book-webfont.woff2';
import MediumWoff2 from 'assets/fonts/aad-brother-1816-medium-webfont.woff2';

const createGlobalTheme = (lightState) => {
  const book = {
    fontFamily: 'Book',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `url(${BookWoff2}) format('woff2')`
  };

  const medium = {
    fontFamily: 'Book',
    fontStyle: 'bold',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `url(${MediumWoff2}) format('woff2')`
  };

  return {
    palette: {
      mode: lightState ? 'light' : 'dark',
      primary: {
        main: lightState ? '#171717' : '#ffffff'
      },
      secondary: {
        main: lightState ? '#ffffff' : '#171717'
      },
      background: {
        default: lightState ? '#ffffff' : '#171717'
      }
    },

    typography: {
      fontFamily: ['"Book"', 'Helvetica', 'Arial', 'sans-serif'].join(', ')
    },

    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              '-webkit-box-shadow': '0 0 0 100px var(--primary-weak) inset',
              '-webkit-text-fill-color': 'var(--text-primary)'
            }
          }
        }
      }
    },

    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [book, medium]
        }
      },

      MuiAppBar: {
        root: {
          border: 'none',
          padding: '0',
          zIndex: '1210'
        }
      },

      MuiPaper: {
        root: {
          backgroundColor: lightState ? '#ffffff' : '#1e1e1e',
          border: 'none',
          boxShadow: lightState ? '0 10px 30px 0 rgba(0,0,0,0.10) !important' : 'none !important',
          overflow: 'hidden',
          padding: '24px'
        },
        rounded: {
          borderRadius: '8px'
        }
      },

      MuiMenu: {
        paper: {
          padding: '0'
        },
        list: {
          paddingBottom: '8px !important',
          paddingTop: '8px !important'
        }
      },

      MuiCard: {
        root: {
          border: 'none',
          boxShadow: lightState ? '0 10px 30px 0 rgba(0,0,0,0.10) !important' : 'none !important',
          overflow: 'hidden',
          padding: '0'
        }
      },

      MuiList: {
        root: {
          padding: '0 !important'
        }
      },

      MuiButton: {
        root: {
          boxShadow: 'none !important'
        }
      },

      MuiDialog: {
        paper: {
          backdropFilter: 'blur(6px)',
          backgroundColor: lightState ? 'rgba(255,255,255,0.8)' : 'rgba(30, 30, 30, 0.8)'
        }
      },

      MUIRichTextEditor: {
        root: {
          border: `1px solid #4d4d4d`,
          borderRadius: '4px !important',
          marginTop: '16px !important',
          marginBottom: '24px !important',
          paddingBottom: '16px',

          '& svg path': {
            fill: '#adadad'
          }
        },
        toolbar: {
          borderBottom: `1px solid #4d4d4d`,
          marginBottom: '24px',
          padding: '8px'
        },
        placeHolder: {
          paddingLeft: '16px',
          paddingRight: '16px',
          position: 'block'
        },
        editor: {
          paddingLeft: '16px',
          paddingRight: '16px'
        }
      }
    }
  };
};

export default createGlobalTheme;
