import { styled } from '@mui/material/styles';
import { Chip, Card } from '@mui/material';
import { grey, amber } from '@mui/material/colors';

const colourGeneration = ({ theme, role, isPublic, lightMode }) => {
  const { secondary, common } = theme.palette;

  let style = '';

  if (isPublic) {
    if (lightMode) {
      style = `
        background: ${(p) => (role !== 'atlassian' ? grey[100] : secondary.main)} !important;

        .MuiCardHeader-avatar * {
          color: ${common.white} !important;
        }
      `;
    } else {
      style = `
        background: ${(p) => (role !== 'atlassian' ? '#1a1a1a' : common.black)} !important;
      `;
    }
  } else {
    style = `
      background: ${amber[500]} !important;

      .MuiChip-root {
        border-color: ${common.black} !important;
      }
    `;

    if (lightMode) {
    } else {
      style = `
        background: ${amber[500]} !important;

        * {
          color: ${common.black};
        }
      `;
    }
  }

  return style;
};

export const CommentCard = styled(({ isPublic, lightMode, ...other }) => <Card {...other} />)`
  ${(p) => colourGeneration(p)}
  padding: ${(p) => p.theme.spacing(2)} !important;
  margin-bottom: ${(p) => p.theme.spacing(4)};
  width: 100%;
`;

export const StyledChip = styled(Chip)`
  border-color: ${(p) => p.theme.palette.common.black} !important;
  color: ${(p) => p.theme.palette.common.black} !important;
`;
