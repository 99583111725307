import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { doSignOut } from 'actions/auth/signOut';
import { SignOutButton } from './styles';

const SignOut = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const logout = () => {
    /** Dispatch to redux */
    dispatch(doSignOut());

    /** Clear the Apollo store */
    client.clearStore();
  };

  return (
    <SignOutButton fullWidth variant="outlined" onClick={logout}>
      Sign out
    </SignOutButton>
  );
};

export default SignOut;
