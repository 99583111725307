import { Adjust, Done, Draw } from '@mui/icons-material';

export const chipColor = (status) => {
  if (status === 'Done') {
    return 'success';
  }

  if (status === 'In Progress') {
    return 'warning';
  }

  return 'error';
};

export const chipIcon = (status) => {
  if (status === 'Done') {
    return <Done />;
  }

  if (status === 'In Progress') {
    return <Draw />;
  }

  return <Adjust />;
};
