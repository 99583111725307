import { Grid } from '@mui/material';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import PageLoader from 'components/Loaders/PageLoader';

const LoadingCardWrapper = ({ page = false }) => {
  return (
    <Grid container sx={{ py: 10 }}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        {page ? <PageLoader /> : <LoadingSpinner />}
      </Grid>
    </Grid>
  );
};

export default LoadingCardWrapper;
