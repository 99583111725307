import { Button, Grid } from '@mui/material';
import { Root, Container, IntroColumn, Logo, IntroText } from 'templates/AuthTemplate/styles';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import * as ROUTES from 'constants/routes';

const NoMatchPage = () => {
  const history = useHistory();

  return (
    <Root>
      <Container container spacing={0} direction="row" alignItems="stretch">
        <IntroColumn item xs={12}>
          <Grid item xs={8}>
            <Link to={ROUTES.DASHBOARD}>
              <Logo />
            </Link>
            <IntroText component="h1" gutterBottom variant="h1">
              404 Page Not Found
            </IntroText>
            <IntroText component="h2" variant="h4" gutterBottom>
              Oh no. It appears this page doesn't exist. We are very sorry.
            </IntroText>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{ mt: 2 }}
              onClick={() => {
                if (history.length > 2) {
                  history.goBack();
                } else {
                  history.push(ROUTES.DASHBOARD);
                }
              }}
            >
              Go Back
            </Button>
          </Grid>
        </IntroColumn>
      </Container>
    </Root>
  );
};

export default NoMatchPage;
