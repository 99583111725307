import { useState } from 'react';
import ProjectTasksCard from 'components/Cards/ProjectTasksCard';
import Tabs from 'components/Tabs';
import SubProjects from './SubProjects';

const ContinuousImprovementsPage = ({
  id,
  projectData,
  error,
  loadingTable,
  setLoadingTable,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  months,
  setMonths,
  selectedMonth,
  setShowOpen,
  setSelectedMonth,
  relatedProjects
}) => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs
        tab={tab}
        tabs={['Continuous Improvements', 'Related Projects']}
        handleTabChange={handleTabChange}
        sx={{ mb: 5 }}
      />
      {tab === 0 && (
        <ProjectTasksCard
          id={id}
          data={projectData}
          error={error}
          loading={loadingTable}
          setLoadingTable={setLoadingTable}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          months={months}
          setMonths={setMonths}
          selectedMonth={selectedMonth}
          setShowOpen={setShowOpen}
          setSelectedMonth={setSelectedMonth}
        />
      )}

      {tab === 1 && <SubProjects relatedProjects={relatedProjects} />}
    </>
  );
};

export default ContinuousImprovementsPage;
