import { GlobalStyles } from '@mui/material';
import SaggacityRegularWoff2 from 'assets/fonts/saggacity-regular.woff2';

const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      '@font-face': {
        fontFamily: 'Saggacity',
        src: `url(${SaggacityRegularWoff2}) format('woff2')`,
        fontWeight: 'normal',
        fontStyle: 'normal'
      },

      a: {
        color: 'inherit'
      },

      '.MuiSelect-root + input': {
        display: 'none'
      },

      '#webpack-dev-server-client-overlay': {
        display: 'none !important'
      }
    })}
  />
);

export default globalStyles;
