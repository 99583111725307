import { styled } from '@mui/material/styles';

export const StickyHeader = styled('div')`
  opacity: ${(p) => (p.isSticky ? '1' : '0')};
  transition: ${(p) =>
    p.theme.transitions.create('all', {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.enteringScreen
    })};
`;
