import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { BottomSpace } from './styles';
import { greeting } from 'helpers/greeting';
import ColouredBox from 'components/Box/ColouredBox';
import StaticIntro from 'components/StaticIntro';
import ProjectsListCard from 'components/Cards/ProjectsListCard';
import TicketCreationCard from 'components/Cards/TicketCreationCard';
import SupportTicketsCard from 'components/Cards/SupportTicketsCard';
import SupportClientsCard from 'components/Cards/SupportClientsCard';
import * as ROUTES from 'constants/routes';

const Dashboard = () => {
  const [supportProjectCount, setSupportProjectCount] = useState(0);
  const { firstname, role, projectCount = 0, support } = useSelector((state) => state.auth);

  const openCount = support?.openCount;
  const closedCount = support?.closedCount;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <BottomSpace>
          <StaticIntro pretitle="Dashboard" title={`${greeting()} ${firstname}`}>
            Here’s what’s happening with your projects today
          </StaticIntro>
        </BottomSpace>

        <BottomSpace>
          <Grid container spacing={3}>
            <Grid item xs>
              <ColouredBox
                number={openCount}
                link={ROUTES.DASHBOARD_SUPPORT}
                title="Open Support Tickets"
                color="red"
              />
            </Grid>
            <Grid item xs>
              <ColouredBox
                number={closedCount}
                link={ROUTES.DASHBOARD_SUPPORT_CLOSED}
                title="Completed Support Tickets"
                color="green"
              />
            </Grid>
            <Grid item xs>
              <ColouredBox number={projectCount} link={ROUTES.DASHBOARD_PROJECTS} title="Projects" />
            </Grid>

            {role === 'superAdmin' && (
              <Grid item xs>
                <ColouredBox
                  number={supportProjectCount}
                  link={ROUTES.DASHBOARD_PROJECTS}
                  title="Support Projects"
                  color="purple"
                />
              </Grid>
            )}
          </Grid>
        </BottomSpace>

        {role === 'superAdmin' && (
          <BottomSpace>
            <SupportClientsCard setSupportProjectCount={setSupportProjectCount} />
          </BottomSpace>
        )}

        <BottomSpace>
          <ProjectsListCard />
        </BottomSpace>

        {role !== 'superAdmin' && (
          <>
            <BottomSpace>
              <SupportTicketsCard status="open" border="true" search={false} ticketsToShow={5} />
            </BottomSpace>

            <BottomSpace>
              <TicketCreationCard />
            </BottomSpace>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
