import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const FormHeaderWrapper = styled('div')`
  text-align: ${(p) => (p.align ? p.align : 'center')};
`;

const setColor = ({ forceColor, lightState, theme }) => {
  return forceColor === 'dark' || forceColor === 'light'
    ? forceColor === 'dark'
      ? theme.palette.common.black
      : theme.palette.common.white
    : lightState
    ? theme.palette.common.black
    : theme.palette.common.white;
};

export const FormTitle = styled(({ forceColor, lightState, ...other }) => <Typography {...other} />)`
  color: ${(p) => setColor(p)};
  text-transform: uppercase;
`;

export const FormSubtitle = styled(Typography)`
  color: ${(p) => p.theme.palette.grey['500']};
`;
