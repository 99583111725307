import { useEffect, useState } from 'react';
import moment from 'moment';
import { Chart, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { LineAxis, GetAppOutlined, PublishOutlined } from '@mui/icons-material';
import { grey, green, red } from '@mui/material/colors';
import { Box, Paper, Typography, MenuItem, InputLabel, Select, FormControl, Grid } from '@mui/material';

const ProjectMonitoringCard = ({ monitor, index }) => {
  const [timeRange, setTimeRange] = useState('6h');
  const [filteredResponseTimes, setFilteredResponseTimes] = useState([]);
  const [averageResponseTime, setAverageResponseTime] = useState(0);
  const [minResponseTime, setMinResponseTime] = useState(0);
  const [maxResponseTime, setMaxResponseTime] = useState(0);

  Chart.register(LinearScale, CategoryScale, PointElement, LineElement);

  const filterResponseTimes = (response_times, timeRange) => {
    const now = moment().unix();
    const cutoff = {
      '1h': moment().subtract(1, 'hours').unix(),
      '6h': moment().subtract(6, 'hours').unix(),
      '24h': moment().subtract(24, 'hours').unix(),
      '7d': moment().subtract(7, 'days').unix()
    }[timeRange];

    const filtered = response_times.filter(({ datetime }) => datetime <= now && datetime > cutoff).reverse();

    const sum = filtered.reduce((total, { value }) => total + value, 0);
    const avg = sum / filtered.length;

    const min = filtered.reduce(
      (min, { value }) => (value !== undefined && value < min ? value : min),
      filtered[0].value
    );
    const max = filtered.reduce(
      (max, { value }) => (value !== undefined && value > max ? value : max),
      filtered[0].value
    );

    return { filtered, avg, min, max };
  };

  useEffect(() => {
    if (monitor && monitor.response_times) {
      const { filtered, avg, min, max } = filterResponseTimes(monitor.response_times, timeRange);
      setFilteredResponseTimes(filtered);
      setAverageResponseTime(avg);
      setMinResponseTime(min);
      setMaxResponseTime(max);
    }
  }, [timeRange, monitor]);

  if (!monitor || !monitor.response_times || monitor.response_times.length === 0) {
    return null;
  }

  const chartData = {
    labels: filteredResponseTimes.map(({ datetime }) => moment.unix(datetime).format('DD/MM/YYYY HH:mm')),
    datasets: [
      {
        label: 'Response Time (ms)',
        data: filteredResponseTimes.map(({ datetime, value }) => ({
          x: moment.unix(datetime).format('DD/MM/YYYY HH:mm'),
          y: value
        })),
        borderColor: 'rgb(242,113,33)',
        fill: false,
        pointRadius: 0,
        tension: 0.4
      }
    ]
  };

  const chartOptions = {
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5
        }
      },
      y: {
        ticks: {
          callback: function (value) {
            return `${value}ms`;
          }
        }
      }
    }
  };

  return (
    <Box mb={5}>
      <Paper sx={{ padding: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography component="h2" variant="h5">
              <strong>Response Times</strong>
            </Typography>
            {monitor?.url && (
              <Typography component="p" variant="body2" sx={{ mb: 2 }}>
                {monitor.url}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
            <FormControl>
              <InputLabel id="time-range-label">Time Range</InputLabel>
              <Select
                labelId="time-range-label"
                value={timeRange}
                onChange={(e) => setTimeRange(e.target.value)}
                label="Time Range"
                MenuProps={{
                  autoFocus: false,
                  disableAutoFocusItem: true,
                  disableEnforceFocus: true,
                  disableAutoFocus: true
                }}
              >
                <MenuItem value="1h">Last 1 hour</MenuItem>
                <MenuItem value="6h">Last 6 hours</MenuItem>
                <MenuItem value="24h">Last 24 hours</MenuItem>
                <MenuItem value="7d">Last 7 days</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Line data={chartData} options={chartOptions} key={`chart-${index}`} />

        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} md={4}>
            <Typography component="h6" variant="h6">
              <LineAxis style={{ color: grey[500], position: 'relative', top: '4px', marginRight: '4px' }} />
              <strong>{averageResponseTime.toFixed(0)}ms</strong>
            </Typography>
            <Typography component="p" variant="body2">
              Average
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography component="h6" variant="h6">
              <GetAppOutlined style={{ color: green[500], position: 'relative', top: '4px', marginRight: '4px' }} />
              <strong>{minResponseTime.toFixed(0)} ms</strong>
            </Typography>
            <Typography component="p" variant="body2">
              Minimum
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography component="h6" variant="h6">
              <PublishOutlined style={{ color: red[500], position: 'relative', top: '4px', marginRight: '4px' }} />
              <strong>{maxResponseTime.toFixed(0)} ms</strong>
            </Typography>
            <Typography component="p" variant="body2">
              Maximum
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ProjectMonitoringCard;
