import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogContent, DialogContentText, Typography, TextField } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { DeleteIconButton, StyledDialogTitle, InlineCode, CenterContent } from '../styles';
import { DELETE_USER_MUTATION } from './data';
import { LIST_ALL_USERS_QUERY } from 'views/Dashboard/SuperAdmin/UsersPage/data';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import * as ROUTES from 'constants/routes';

const UserManagementActions = ({ uid, email }) => {
  const [open, setOpen] = useState(false);
  const [confirmUser, setConfirmUser] = useState(null);
  const { email: currentUserEmail } = useSelector((state) => state.auth);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmationChange = (email) => {
    setConfirmUser(email);
  };

  const [deleteUser, { loading, error, data }] = useMutation(DELETE_USER_MUTATION, {
    refetchQueries: [{ query: LIST_ALL_USERS_QUERY }],
    onCompleted: () => {
      handleClose();
    }
  });

  const handleDeleteUser = () => {
    if (confirmUser === email) {
      deleteUser({ variables: { uid } });
    }
  };

  return (
    <DefaultCardWrapper title="Other actions">
      {currentUserEmail === email ? (
        <Typography variant="caption">Sorry but you are not able to delete yourself.</Typography>
      ) : (
        <>
          <Typography variant="caption">
            Remove this this customer’s data if he requested that, if not please be aware that what has been deleted can
            never brought back
          </Typography>

          <DeleteIconButton startIcon={<DeleteOutline />} variant="contained" onClick={handleClickOpen} sx={{ mt: 2 }}>
            Delete user account
          </DeleteIconButton>

          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <StyledDialogTitle id="form-dialog-title">Confirm User Deletion</StyledDialogTitle>
            <DialogContent>
              {!data && !loading && (
                <>
                  <DialogContentText>
                    If you are sure you want to delete <InlineCode>{email}</InlineCode>, confirm deletion of the user by
                    entering their email address in the input below.
                    <TextField
                      variant="outlined"
                      name="confirmuser"
                      label="Confirm User"
                      margin="normal"
                      required
                      fullWidth
                      onChange={(e) => handleConfirmationChange(e.target.value)}
                    />
                  </DialogContentText>

                  <DeleteIconButton
                    disabled={confirmUser === email ? false : true}
                    variant="contained"
                    onClick={handleDeleteUser}
                  >
                    Confirm customer deletion
                  </DeleteIconButton>

                  <Button onClick={handleClose} variant="container" color="primary">
                    Cancel
                  </Button>
                </>
              )}

              {loading && (
                <CenterContent>
                  <DialogContentText>User is being deleted...</DialogContentText>
                  <LoadingSpinner />
                </CenterContent>
              )}

              {data && (
                <CenterContent>
                  <DialogContentText>
                    This user has now been deleted.
                    <br />
                    You can now return to the user list.
                  </DialogContentText>
                  <Button variant="contained" color="primary" component={Link} to={ROUTES.DASHBOARD_SUPER_ADMIN_USERS}>
                    Go back to customer list
                  </Button>
                </CenterContent>
              )}

              {error && <p>Unable to delete customers account.</p>}
            </DialogContent>
          </Dialog>
        </>
      )}
    </DefaultCardWrapper>
  );
};

export default UserManagementActions;
