import { styled } from '@mui/material/styles';
import { IconButton, Popover } from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  padding: 0 !important;
`;

export const StyledPopover = styled(({ ...other }) => <Popover classes={{ paper: 'paper' }} {...other} />)`
  & .paper {
    padding: ${(p) => p.theme.spacing(2)};
  }
`;
