import {
  SupervisorAccount,
  FolderOpen,
  Business,
  HomeOutlined,
  DashboardOutlined,
  GraphicEq,
  AccessTime,
  RocketLaunch,
  SupportAgent
} from '@mui/icons-material';
import * as ROUTES from '../../../constants/routes';

export const navigation = [
  {
    route: ROUTES.DASHBOARD,
    icon: <HomeOutlined />,
    text: 'Dashboard',
    label: 'Dashboard'
  },
  {
    route: ROUTES.DASHBOARD_PROJECTS,
    icon: <DashboardOutlined />,
    text: 'Projects',
    label: 'Projects'
  },
  {
    route: ROUTES.DASHBOARD_SUPPORT,
    icon: <GraphicEq />,
    text: 'Support',
    label: 'Support'
  }
];

export const agencyNavigation = [
  {
    text: 'Admin',
    restricted: 'agencyAdmin',
    children: [
      {
        route: ROUTES.DASHBOARD_ADMIN_USERS,
        icon: <SupervisorAccount />,
        text: 'Staff',
        label: 'Manage Staff',
        restricted: 'agencyAdmin'
      },
      {
        route: ROUTES.DASHBOARD_ADMIN_PROJECTS,
        icon: <FolderOpen />,
        text: 'Projects',
        label: 'Manage Projects',
        restricted: 'agencyAdmin'
      }
    ]
  }
];

export const superNavigation = [
  {
    text: 'Management',
    restricted: 'superAdmin',
    children: [
      {
        route: ROUTES.DASHBOARD_SUPER_ADMIN_USERS,
        icon: <SupervisorAccount />,
        text: 'Users',
        label: 'Manage Users',
        restricted: 'superAdmin'
      },
      {
        route: ROUTES.DASHBOARD_SUPER_ADMIN_PROJECTS,
        icon: <FolderOpen />,
        text: 'Projects',
        label: 'Manage Projects',
        restricted: 'superAdmin'
      },
      {
        route: ROUTES.DASHBOARD_SUPER_ADMIN_AGENCIES,
        icon: <Business />,
        text: 'Agencies',
        label: 'Manage Agencies',
        restricted: 'superAdmin'
      },
      {
        route: ROUTES.DASHBOARD_SUPER_ADMIN_TIME_TRACKING,
        icon: <AccessTime />,
        text: 'Time Tracking',
        label: 'View Time Tracking',
        restricted: 'superAdmin'
      },
      {
        route: ROUTES.DASHBOARD_SUPER_ADMIN_DEPLOYMENTS,
        icon: <RocketLaunch />,
        text: 'Deployments',
        label: 'Manage Deployments',
        restricted: 'superAdmin',
        featureFlag: 'deployments'
      },
      {
        route: ROUTES.DASHBOARD_SUPER_ADMIN_SUPPORT,
        icon: <SupportAgent />,
        text: 'Support',
        label: 'Support Overview',
        restricted: 'superAdmin'
      }
    ]
  }
];
