import { gql } from '@apollo/client';
import {
  VisibilityOutlined,
  ArchitectureOutlined,
  // IntegrationInstructionsOutlined,
  // LooksOutlined,
  DnsOutlined,
  // DrawOutlined
  BubbleChartOutlined
} from '@mui/icons-material';
import * as ROUTES from 'constants/routes';

export const PROJECT_QUERY = gql`
  query project($id: ID!, $startDate: String, $endDate: String, $showOpen: Boolean) {
    project(id: $id) {
      title
      summary
      projectType
      projectCode
      supportClient
      websiteUrl
      projectLead {
        email
        firstname
        lastname
        phone
        jobTitle
      }
      clientDetails {
        email
        first_name
        importance
        last_name
        phone
      }
      ssl {
        valid
        validFrom
        validTo
        daysRemaining
      }
      contractStartDate
      tasks(startDate: $startDate, endDate: $endDate, showOpen: $showOpen) {
        tasks {
          id
          key
          fields {
            status {
              name
              statusCategory {
                name
              }
            }
            summary
            description
            timeestimate
            timespent
          }
        }
        allowance {
          hourlyRateForTimePeriod
          totalAvailableMinutes
          totalRemainingMinutes
          totalSpentMinutes
          allowance {
            date
            hourly_rate
            hours_available
          }
        }
      }
      onepage {
        weeks
        risks
        project_start
        issues {
          name
          issues {
            name
            labels
            date
            components
            status
            type
            week_due
          }
        }
        features {
          id
          name
        }
      }
      relatedProjects {
        key
        fields {
          summary
          timeestimate
          timespent
          status {
            name
            statusCategory {
              name
            }
          }
        }
        tasks {
          key
          fields {
            summary
            description
            timeestimate
            timespent
            status {
              name
              statusCategory {
                name
              }
            }
          }
        }
      }
      servers {
        name
        environment
        deployments {
          records {
            deployer
            deployer_avatar
            branch
            status
            timestamps {
              started_at
              duration
              completed_at
            }
          }
        }
      }
      monitors {
        id
        friendly_name
        url
        type
        response_times {
          datetime
          value
        }
        logs {
          type
          type_name
          datetime
          duration
          reason {
            code
            detail
          }
        }
      }
    }
  }
`;

export const projectSteps = [
  {
    route: '/',
    icon: VisibilityOutlined,
    label: 'Overview',
    description: `Description coming soon...`
  },
  {
    route: ROUTES.DASHBOARD_PROJECT_PLANNING,
    icon: ArchitectureOutlined,
    label: 'Planning',
    description: `Description coming soon...`
  },
  // {
  //   route: ROUTES.DASHBOARD_PROJECT_DESIGN,
  //   icon: DrawOutlined,
  //   label: 'Design',
  //   description: `Description coming soon...`
  // },
  // {
  //   route: ROUTES.DASHBOARD_PROJECT_DEVELOPMENT,
  //   icon: IntegrationInstructionsOutlined,
  //   label: 'Development',
  //   description: `Description coming soon...`
  // },
  // {
  //   route: ROUTES.DASHBOARD_PROJECT_REVIEW,
  //   icon: LooksOutlined,
  //   label: 'Review',
  //   description: `Description coming soon...`
  // },
  {
    route: ROUTES.DASHBOARD_PROJECT_HOSTING,
    icon: DnsOutlined,
    label: 'Hosting',
    description: `Description coming soon...`
  },
  {
    route: ROUTES.DASHBOARD_PROJECT_CI,
    icon: BubbleChartOutlined,
    label: 'Continuous Improvements',
    description: `Description coming soon...`
  }
];
