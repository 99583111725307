import { Grid, Typography } from '@mui/material';
import OnePageProjectManager from './OnePageProjectManager';

const PlanningPage = ({ project }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {project?.onepage ? (
          <OnePageProjectManager onepage={project.onepage} />
        ) : (
          <Typography variant="h6">No planning information found</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default PlanningPage;
