import React from 'react';
import { styled } from '@mui/material/styles';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import { yellow } from '@mui/material/colors';

const drawerWidth = 240;

export const StyledDrawer = styled(({ open, onOpen, onClose, ...other }) => (
  <Drawer classes={{ paper: open ? 'drawerOpenPaper' : 'drawerClosePaper' }} {...other} />
))`
  flex-shrink: 0;
  overflow-x: hidden;
  white-space: nowrap;
  transition: ${(p) =>
    p.theme.transitions.create('width', {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.enteringScreen
    })};
  width: ${(p) => (p.open ? `${drawerWidth}px` : p.theme.spacing(11) + 1)};

  .MuiPaper-root {
    background: transparent;
    border-bottom: none;
    border-top: none;
    border-left: none;
    padding: ${(p) => p.theme.spacing(2)} !important;
    transition: ${(p) =>
      p.theme.transitions.create('all', {
        easing: p.theme.transitions.easing.sharp,
        duration: p.theme.transitions.duration.leavingScreen
      })};
    width: ${drawerWidth}px;

    @media (max-width: 600px) {
      position: fixed;
    }

    @media (max-width: 600px) {
      background: #1e1e1e;
      left: ${(p) => (p.open ? '0' : '-100%')} !important;
      width: ${drawerWidth}px !important;
      z-index: 9999;
    }
  }

  & .drawerClosePaper {
    overflow-x: hidden;
    width: ${(p) => p.theme.spacing(11)};

    @media (max-width: 600px) {
      width: ${drawerWidth}px;
    }
  }
`;

export const StyledList = styled(List)`
  height: 100%;
  margin-top: ${(p) => p.theme.spacing(2)};
  position: relative;
`;

export const StyledListItem = styled(ListItem)`
  border-radius: ${(p) => p.theme.spacing(1)};
  color: ${(p) => p.theme.palette.common.default};
  transition: ${(p) =>
    p.theme.transitions.create('all', {
      easing: p.theme.transitions.easing.easeInOut,
      duration: p.theme.transitions.duration.standard
    })} !important;

  svg {
    fill: ${(p) => p.theme.palette.common.default};
  }

  &.active {
    background: linear-gradient(135deg, ${yellow[700]} 30%, ${yellow[500]} 90%);
    color: ${(p) => p.theme.palette.common.black} !important;

    svg {
      fill: ${(p) => p.theme.palette.common.black} !important;
    }
  }
`;

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    font-weight: bold;
  }
`;

export const MenuTitle = styled('span')`
  color: ${(p) => p.theme.palette.common.default};
  display: inline-block;
  margin: ${(p) => p.theme.spacing(3)} 0 ${(p) => p.theme.spacing(1)};
  padding: 0 20px;
  text-transform: uppercase;
`;
