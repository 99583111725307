import { gql } from '@apollo/client';

export const GET_PROJECT_QUERY = gql`
  query project($id: ID!) {
    project(id: $id) {
      title
      summary
      projectType
      projectCode
      websiteUrl
      monitors {
        id
        friendly_name
        url
        type
        response_times {
          datetime
          value
        }
        logs {
          type
          type_name
          datetime
          duration
          reason {
            code
            detail
          }
        }
      }
      ssl {
        valid
        message
      }
      screenshot {
        secure_url
      }
    }
  }
`;
