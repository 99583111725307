import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Typography, CardHeader } from '@mui/material';
import {
  StyledCard,
  StyledCardMedia,
  StyledCardHeaderIcon,
  PositionedIcon,
  StyledCardContent,
  StyledCardActions,
  ExternalLink,
  Icon
} from './styles';
import * as ROUTES from 'constants/routes';

const ProjectCard = ({ project: { id, title, summary, screenshot, websiteUrl } }) => {
  const lightMode = useSelector((state) => state.type.lightMode);

  const FeatureImage = ({ screenshot }) => {
    if (screenshot && screenshot.secure_url) {
      let url = screenshot.secure_url;
      let resizeUrl = url.replace(/(\/upload\/)(v\d+\/)/, '$1c_fill,h_452,w_908/$2');

      return <StyledCardMedia image={resizeUrl} title={title} />;
    } else {
      return (
        <StyledCardHeaderIcon lightMode={lightMode}>
          <PositionedIcon />
        </StyledCardHeaderIcon>
      );
    }
  };

  return (
    <StyledCard>
      <Link to={`${ROUTES.DASHBOARD_PROJECT_ROOT}/${id}`}>
        <FeatureImage screenshot={screenshot} />
      </Link>
      <CardHeader title={title} />
      <StyledCardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {summary}
        </Typography>
      </StyledCardContent>
      <StyledCardActions>
        <Button
          variant="contained"
          component={Link}
          to={`${ROUTES.DASHBOARD_PROJECT_ROOT}/${id}`}
          color="primary"
          size="small"
        >
          Manage
        </Button>
        <ExternalLink href={websiteUrl} target="_blank" rel="noopener noreferrer">
          <Button variant="outlined" color="primary" size="small">
            View <Icon />
          </Button>
        </ExternalLink>
      </StyledCardActions>
    </StyledCard>
  );
};

/** Type checking */
ProjectCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  summary: PropTypes.string,
  screenshot: PropTypes.string,
  websiteUrl: PropTypes.string
};

export default ProjectCard;
