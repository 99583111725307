import * as Yup from 'yup';

export const validationSchema = Yup.object({
  subject: Yup.string('Ticket subject').required('A ticket subject is required'),
  project: Yup.string('Select a project').required('You must select a project'),
  priority: Yup.string('Select a priority').required('You must select a priority'),
  comment: Yup.string('Please provide a detailed description of the issue').required(
    'Please provide a detailed description of the issue'
  )
});
