import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import AttachmentModal from '../AttachmentModal';
import AttachmentThumbs from '../AttachmentThumbs';
import { DropZoneContainer, AttachmentsContainer, DropText } from './styles';

const DropZone = ({ attachments, setAttachments, setFieldValue, removeAll }) => {
  const [modal, setModal] = useState({
    title: null,
    image: null,
    openModal: false
  });

  const handleOpenModal = (title, image) => {
    setModal({ title, image, openModal: true });
  };
  const handleCloseModal = () => {
    setModal({ title: null, image: null, openModal: false });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf',
    onDrop: (acceptedFiles) => {
      setFieldValue('files', acceptedFiles);

      setAttachments(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const removeFile = (fileToRemove) => {
    const newFiles = [...attachments];
    newFiles.splice(newFiles.indexOf(fileToRemove), 1);
    setAttachments(
      newFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    );
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      attachments.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [attachments]
  );

  return (
    <section>
      <DropZoneContainer {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <DropText>Drag 'n' drop some files here, or click to select files</DropText>
      </DropZoneContainer>
      <AttachmentsContainer>
        <AttachmentThumbs attachments={attachments} handleOpenModal={handleOpenModal} removeFile={removeFile} />
        <AttachmentModal
          title={modal.title}
          image={modal.image}
          onCloseModal={handleCloseModal}
          openModal={modal.openModal}
        />
      </AttachmentsContainer>
    </section>
  );
};

export default DropZone;
