import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider, MenuList, MenuItem } from '@mui/material';
import { StyledIconButtonSmall, LogoutWrapper, StyledPopover } from '../LoggedInMenu/styles';
import AvatarGenerator from 'components/AvatarGenerator';
import SignOut from 'views/Auth/SignOut';
import * as ROUTES from 'constants/routes';

const ProfileMenu = () => {
  const [profileNav, setProfileNav] = useState(null);
  const { email, firstname, lastname } = useSelector((state) => state.auth);
  const lightMode = useSelector((state) => state.type.lightMode);

  const menu = [
    {
      route: ROUTES.DASHBOARD_ACCOUNT,
      text: 'Profile'
    },
    {
      route: ROUTES.DASHBOARD_ACCOUNT_SECURITY,
      text: 'Change Password'
    }
  ];

  return (
    <>
      <StyledIconButtonSmall
        aria-owns={profileNav ? 'material-appbar' : undefined}
        aria-haspopup="true"
        onClick={(e) => setProfileNav(e.currentTarget)}
      >
        <AvatarGenerator email={email} size={60} firstname={firstname} lastname={lastname} />
      </StyledIconButtonSmall>

      <StyledPopover
        anchorEl={() => profileNav}
        open={Boolean(profileNav)}
        onClose={() => setProfileNav(null)}
        lightMode={lightMode}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuList>
          {menu.map(({ route, text }, index) => (
            <MenuItem key={index} component={Link} to={route}>
              {text}
            </MenuItem>
          ))}
        </MenuList>

        <Divider />

        <LogoutWrapper>
          <SignOut />
        </LogoutWrapper>
      </StyledPopover>
    </>
  );
};

export default ProfileMenu;
