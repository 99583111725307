import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// import SiteFooter from 'components/Footer/SiteFooter';
import * as ROUTES from 'constants/routes';

const SiteTemplate = ({ component: Component, route, disabled }) => {
  if (disabled) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  return (
    <>
      <Component route={route} />
      {/* <SiteFooter /> */}
    </>
  );
};

/** Type checking */
SiteTemplate.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  route: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default SiteTemplate;
