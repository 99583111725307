import moment from 'moment';
import {
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';
import { formatDuration } from 'helpers/content';
import { KeyboardArrowRight } from '@mui/icons-material';

const ExtraRowDetails = ({ deployments, open, onClose }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
    <DialogTitle>Extra Information</DialogTitle>
    <DialogContent>
      <Table size="small" aria-label="deployments">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '30%' }}>Branch</TableCell>
            <TableCell sx={{ width: '10%' }}>Deployer</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Started</TableCell>
            <TableCell>Completed</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deployments.records.map(
            (
              {
                branch,
                deployer,
                deployer_avatar,
                deployment_url,
                status,
                timestamps: { started_at, duration, completed_at }
              },
              index
            ) => (
              <TableRow key={index} sx={{ '&:last-child td': { borderBottom: '0' } }}>
                <TableCell>{branch}</TableCell>
                <TableCell>{deployer}</TableCell>
                <TableCell>
                  <Chip label={status} color={status === 'completed' ? 'success' : 'error'} />
                </TableCell>
                <TableCell>{moment(started_at).format('Do MMM YYYY, h:mmA')}</TableCell>
                <TableCell>{moment(completed_at).format('Do MMM YYYY, h:mmA')}</TableCell>
                <TableCell>{formatDuration(duration)}</TableCell>
                <TableCell align="right">
                  <IconButton size="small" target="_blank" href={deployment_url}>
                    <KeyboardArrowRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </DialogContent>
  </Dialog>
);

export default ExtraRowDetails;
