import { useSelector } from 'react-redux';
import { useRouteMatch, Link } from 'react-router-dom';
import { List } from '@mui/material';
import { Add, WorkOutline, WorkOff } from '@mui/icons-material';
import { StyledMenuButton, StyledCreateButton, StyledListItem, StyledNav } from './styles';
import * as ROUTES from 'constants/routes';

const SupportSidebar = () => {
  const match = useRouteMatch();
  const { path } = match;

  const {
    support: { openCount, closedCount }
  } = useSelector((state) => state.auth);

  const menu = [
    {
      route: ROUTES.DASHBOARD_SUPPORT,
      icon: <WorkOutline />,
      title: 'Open',
      value: openCount
    },
    {
      route: ROUTES.DASHBOARD_SUPPORT_SOLVED,
      icon: <WorkOff />,
      title: 'Resolved',
      value: closedCount
    }
  ];

  return (
    <StyledNav>
      <List>
        {menu.map(({ route, icon, title, value }, index) => (
          <StyledListItem key={index}>
            <StyledMenuButton
              startIcon={icon}
              component={Link}
              to={route}
              className={path === route ? 'activeBtn' : ''}
            >
              {title} ({value})
            </StyledMenuButton>
          </StyledListItem>
        ))}

        <StyledListItem style={{ float: 'right' }}>
          <StyledCreateButton
            component={Link}
            to={ROUTES.DASHBOARD_SUPPORT_CREATE}
            variant="contained"
            endIcon={<Add />}
          >
            Create Ticket
          </StyledCreateButton>
        </StyledListItem>
      </List>
    </StyledNav>
  );
};

export default SupportSidebar;
