import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'helpers/History';
import { PrivateRoute } from 'components/Routes/PrivateRoute';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'views/Handlers/ErrorPage';
import routes from 'config/routes';
import fetchUser from 'actions/auth/fetchUser';
import createGlobalTheme from 'assets/styles/globalTheme';
import globalStyles from 'assets/styles/globalStyles';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loggedIn, role } = useSelector((state) => state.auth);
  const lightMode = useSelector((state) => state.type.lightMode);
  const [intro, setIntro] = useState(null);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [lightState, setLightState] = useState(prefersDarkMode);

  const customTheme = createGlobalTheme(lightState);
  const theme = createTheme({ ...customTheme });

  const restrictedRoutePaths = routes.filter((route) => route.restricted).map((route) => route.path);

  useEffect(() => {
    if (restrictedRoutePaths.includes(location.pathname)) {
      dispatch(fetchUser());
    }

    // Set light mode based on user preference
    setLightState(lightMode);
  }, [lightMode, location, dispatch, restrictedRoutePaths]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {globalStyles}

      <ErrorBoundary FallbackComponent={ErrorPage} onReset={() => history.push('/')}>
        <Router history={history}>
          <Switch>
            {routes.map(
              (
                {
                  component,
                  path,
                  template: Template,
                  restricted,
                  title,
                  description,
                  routeRole,
                  featureFlag,
                  extraSidebar,
                  disabled
                },
                key
              ) => {
                return restricted ? (
                  <PrivateRoute
                    exact
                    path={path}
                    key={key}
                    loggedIn={loggedIn}
                    userRole={role}
                    routeRole={routeRole}
                    featureFlag={featureFlag}
                    render={(route) => (
                      <Template
                        component={component}
                        route={route}
                        title={title}
                        description={description}
                        extraSidebar={extraSidebar}
                        intro={intro}
                        setIntro={setIntro}
                      />
                    )}
                  />
                ) : (
                  <Route
                    exact
                    path={path}
                    key={key}
                    render={(route) => (
                      <Template
                        component={component}
                        route={route}
                        title={title}
                        description={description}
                        extraSidebar={extraSidebar}
                        intro={intro}
                        setIntro={setIntro}
                        disabled={disabled}
                      />
                    )}
                  />
                );
              }
            )}
          </Switch>
        </Router>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
