import { useQuery } from '@apollo/client';
import { FORECASTING_QUERY } from './data';
import StaticIntro from 'components/StaticIntro';
import ForecastingTable from './ForecastingTable';
import PageUnavailable from 'components/Messages/PageUnavailable';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';

const ForecastingPage = () => {
  const { loading, error, data } = useQuery(FORECASTING_QUERY);

  return (
    <>
      <StaticIntro pretitle="Management" title="Forecasting" />
      {loading && <LoadingCardWrapper />}
      {error && <PageUnavailable />}
      {data && <ForecastingTable data={data} />}
    </>
  );
};

export default ForecastingPage;
