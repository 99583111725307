import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { CardContent, Typography } from '@mui/material';
import { StyledCard, CardStat } from './styles';
import * as ROUTES from 'constants/routes';

const SslCard = ({ ssl, support }) => (
  <StyledCard>
    <CardStat positive={ssl.valid ? true : false}>
      <Typography variant="h5" style={{ color: 'white' }}>
        SSL: {ssl.valid ? 'Valid' : 'Not valid'}
      </Typography>
    </CardStat>

    <CardContent>
      {ssl.validFrom && ssl.validTo && (
        <Typography variant="subtitle1" color="textSecondary">
          <Moment format="Do MMMM YYYY">{ssl.validFrom}</Moment> to <Moment format="Do MMMM YYYY">{ssl.validTo}</Moment>
        </Typography>
      )}
      {ssl.valid ? (
        <>
          <Typography component="p">
            You have <strong>{ssl.daysRemaining} days</strong> remaining before your SSL certicifate expires.
          </Typography>
          <Typography component="p">
            {support ? (
              "But don't worry, we will take care of this for you."
            ) : (
              <>
                Please raise a{' '}
                <Link style={{ color: 'inherit' }} to={ROUTES.DASHBOARD_SUPPORT_CREATE}>
                  support ticket
                </Link>{' '}
                with us to discuss your SSL renewal.
              </>
            )}
          </Typography>
        </>
      ) : (
        <Typography component="p">
          It seems there's an issue with your SSL. If you are unsure what the issue is, please contact us and we will
          investigate for you.
        </Typography>
      )}
    </CardContent>
  </StyledCard>
);

/** Type checking */
SslCard.propTypes = {
  ssl: PropTypes.object.isRequired,
  support: PropTypes.bool
};

export default SslCard;
