import React from 'react';
import { styled } from '@mui/material/styles';
import { LinearProgress, Tooltip } from '@mui/material';
import { amber, red, green } from '@mui/material/colors';

export const StyledActionButtons = styled('div')`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`;

export const StyledLinearProgress = styled(({ value, ...other }) => (
  <Tooltip title={`${value}% complete`}>
    <LinearProgress classes={{ barColorPrimary: 'barColorPrimary' }} value={value} {...other} />
  </Tooltip>
))`
  & .barColorPrimary {
    background-color: ${(props) => {
      if (props.value < 20) {
        return red[900];
      }

      if (props.value < 50) {
        return red[600];
      }

      if (props.value >= 50 && props.value < 99) {
        return amber[700];
      }

      if (props.value === 100) {
        return green[700];
      }
    }};
  }
`;
