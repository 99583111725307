import { useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { Close, AttachFileOutlined } from '@mui/icons-material';
import { CloseButton, AttachmentList, AttachmentListItem, Thumb } from './styles';
import AttachmentModal from 'components/Attachments/AttachmentModal';

const AttachmentThumbs = ({ attachments, removeFile, title }) => {
  const [modal, setModal] = useState({ openModal: false });

  const handleOpenModal = (title, image) => {
    setModal({ title, image, openModal: true });
  };
  const handleCloseModal = () => {
    setModal({ openModal: false });
  };

  return (
    <>
      {title && (
        <Typography component="h4" variant="h6" gutterBottom>
          {title}
        </Typography>
      )}
      <AttachmentList>
        {attachments &&
          attachments.map((file, index) => {
            let image = file.preview ? URL.createObjectURL(file) : file.content_url;
            if (file.content) {
              image = file.content;
            }

            const thumb = file.thumbnail ? file.thumbnail : image;
            const title = file.name ? file.name : file.file_name;
            const type = file.type ? file.type : file.content_type;

            return (
              <AttachmentListItem key={index}>
                <Tooltip placement="bottom" title={title}>
                  {type === 'application/pdf' ? (
                    <Thumb>
                      <AttachFileOutlined />
                    </Thumb>
                  ) : (
                    <Thumb src={thumb} onClick={() => handleOpenModal(title, image)} />
                  )}
                </Tooltip>

                {removeFile ? (
                  <CloseButton color="primary" size="small" onClick={() => removeFile(file)}>
                    <Close />
                  </CloseButton>
                ) : null}
              </AttachmentListItem>
            );
          })}
      </AttachmentList>

      <AttachmentModal
        title={modal.title}
        image={modal.image}
        onCloseModal={handleCloseModal}
        openModal={modal.openModal}
      />
    </>
  );
};

export default AttachmentThumbs;
