import { Grid, Typography } from '@mui/material';
import ProjectList from 'components/Lists/ProjectList';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';

const SupportProjectsListCard = ({ projects }) => {
  return (
    <DefaultCardWrapper
      header={
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              <strong>Support Client Projects</strong>
            </Typography>
          </Grid>
        </Grid>
      }
    >
      <ProjectList projects={projects} list={true} />
    </DefaultCardWrapper>
  );
};

export default SupportProjectsListCard;
