import { Fragment } from 'react';
import { StyledWrapper } from './styles';
import { FeaturesComponent, WeeksComponent } from './Elements';
import { dueThisWeek, isTaskInFeature } from './Helpers';

const OnePageProjectManager = ({ onepage }) => {
  if (onepage) {
    let globalIndex = 4;

    const { weeks, risks, project_start, issues, features } = onepage;

    /** Variables */
    let originalProjectStart = project_start;

    let featuresCount = features.length;
    let status = 6; // three double width columns
    let releaseWidth = 20;

    let columns = featuresCount + weeks + status + releaseWidth;
    let logo = featuresCount + 1;

    let productOwnerStart = logo;
    let productOwnerEnd = productOwnerStart + releaseWidth;
    let projectStart = productOwnerEnd;
    let projectEnd = projectStart + weeks;

    let taskChartWidth = Math.floor((weeks + status) / 3.5);
    let overallChartWidth = weeks + status - (taskChartWidth + taskChartWidth);

    let taskChart1Start = projectStart;
    let taskChart1End = projectStart + taskChartWidth;

    let taskChart2Start = taskChart1End;
    let taskChart2End = taskChart2Start + taskChartWidth;

    let overallChartStart = taskChart2End;
    let overallChartEnd = overallChartStart + overallChartWidth;

    let dateStart = projectEnd;
    let dateEnd = dateStart + status;

    let status1Start = dateStart;
    let status1End = status1Start + 2;

    let status2Start = status1End;
    let status2End = status2Start + 2;

    let status3Start = status2End;
    let status3End = status3Start + 2;

    return (
      <StyledWrapper
        columns={columns}
        logo={logo}
        productOwnerStart={productOwnerStart}
        productOwnerEnd={productOwnerEnd}
        projectStart={projectStart}
        projectEnd={projectEnd}
        status={status}
        dateStart={dateStart}
        dateEnd={dateEnd}
        taskChart1Start={taskChart1Start}
        taskChart1End={taskChart1End}
        taskChart2Start={taskChart2Start}
        taskChart2End={taskChart2End}
        overallChartStart={overallChartStart}
        overallChartEnd={overallChartEnd}
        status1Start={status1Start}
        status1End={status1End}
        status2Start={status2Start}
        status2End={status2End}
        status3Start={status3Start}
        status3End={status3End}
      >
        <div className="wrapper">
          <div className="row_3 features header">Feature Sets</div>
          <div className="row_3 releases header">Releases and tasks</div>
          <div className="row_3 schedule header">Schedule</div>
          <div className="row_3 status header">Task Status</div>

          <>
            {issues.map((release, r) => {
              return (
                <Fragment key={r}>
                  {[...Array(featuresCount)].map((_, f) => {
                    return <div key={f} className={`feature subheader`} style={{ gridRow: globalIndex }}></div>;
                  })}

                  <div className={`release subheader`} style={{ gridRow: globalIndex }}>
                    {release.name}
                  </div>

                  {[...Array(weeks)].map((_, w) => {
                    return <div key={w} className={`week subheader`} style={{ gridRow: globalIndex }}></div>;
                  })}

                  <div className={`status_1 subheader`} style={{ gridRow: globalIndex }}></div>
                  <div className={`status_2 subheader`} style={{ gridRow: globalIndex }}></div>
                  <div className={`status_3 subheader`} style={{ gridRow: globalIndex }}></div>

                  <span style={{ display: 'none' }}>{globalIndex++}</span>

                  {release.issues.map((task, t) => {
                    let row_class = '';
                    if (task.labels.includes('ClientToProvide')) {
                      row_class += ' client-to-provide';
                    }

                    return (
                      <Fragment key={t}>
                        {[...Array(featuresCount)].map((_, f) => {
                          const feature = isTaskInFeature(features[f], task);
                          return (
                            <div key={f} className={`feature ${feature}`} style={{ gridRow: globalIndex }}>
                              {feature && <span className="circle"></span>}
                            </div>
                          );
                        })}

                        <div
                          className={`release ${row_class}`}
                          style={{ gridRow: globalIndex }}
                          dangerouslySetInnerHTML={{ __html: task.name }}
                        />

                        {[...Array(weeks)].map((_, w) => {
                          const due = dueThisWeek(w + 1, task);

                          return (
                            <div key={w} className={`week ${due.class}`} style={{ gridRow: globalIndex }}>
                              {due.class !== '' && (
                                <span
                                  className="circle"
                                  title={`${due.milestone !== '' ? due.milestone + ' - ' : ''}${new Date(
                                    task.date
                                  ).toLocaleDateString()}`}
                                ></span>
                              )}
                            </div>
                          );
                        })}

                        {(() => {
                          let status_1 = 'none';
                          let status_2 = 'none';
                          let status_3 = 'none';

                          switch (task.status) {
                            case 'Backlog':
                              status_1 = 'backlog';
                              status_2 = 'none';
                              status_3 = 'none';
                              break;
                            case 'Selected for Development':
                              status_1 = 'backlog';
                              status_2 = 'none';
                              status_3 = 'none';
                              break;
                            case 'In Progress':
                              status_1 = 'in_progress';
                              status_2 = 'none';
                              status_3 = 'none';
                              break;
                            case 'Done':
                              status_1 = 'none';
                              status_2 = 'none';
                              status_3 = 'adequate';
                              break;
                            default:
                              status_1 = 'none';
                              status_2 = 'none';
                              status_3 = 'none';
                          }

                          return (
                            <>
                              <div className={`status_1 ${status_1}`} style={{ gridRow: globalIndex }}></div>
                              <div className={`status_2 ${status_2}`} style={{ gridRow: globalIndex }}></div>
                              <div className={`status_3 ${status_3}`} style={{ gridRow: globalIndex }}></div>
                            </>
                          );
                        })()}

                        <span style={{ display: 'none' }}>{globalIndex++}</span>
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
          </>

          <div className="features header" style={{ gridRow: globalIndex }}></div>
          <div className="releases header" style={{ gridRow: globalIndex }}></div>
          <div className="schedule header" style={{ gridRow: globalIndex }}></div>
          <div className="status header" style={{ gridRow: globalIndex }}></div>

          <span style={{ display: 'none' }}>{globalIndex++}</span>

          <>
            {risks.map((risk, r) => {
              let elements = [];

              for (let f = 1; f <= featuresCount; f++) {
                elements.push(<div className="feature" style={{ gridRow: globalIndex }} key={`f-${f}`}></div>);
              }

              elements.push(
                <div className="release" style={{ gridRow: globalIndex }} key={`r-${r}`}>
                  {risk}
                </div>
              );

              for (let w = 1; w <= weeks; w++) {
                elements.push(<div className="week" style={{ gridRow: globalIndex }} key={`w-${w}`}></div>);
              }

              if (r === 0) {
                elements.push(
                  <div
                    className="status_1"
                    style={{ gridRow: `${globalIndex} / ${globalIndex + risks.length + 2}` }}
                    key="status_1"
                  >
                    <div className="rotateText">
                      <span>Backlog or in Progress</span>
                    </div>
                  </div>,
                  <div
                    className="status_2"
                    style={{ gridRow: `${globalIndex} / ${globalIndex + risks.length + 2}` }}
                    key="status_2"
                  >
                    <div className="rotateText">
                      <span>Issues</span>
                    </div>
                  </div>,
                  <div
                    className="status_3"
                    style={{ gridRow: `${globalIndex} / ${globalIndex + risks.length + 2}` }}
                    key="status_3"
                  >
                    <div className="rotateText">
                      <span>Completed</span>
                    </div>
                  </div>
                );
              }

              globalIndex++;
              return elements;
            })}
          </>

          <>
            <div className="features" style={{ gridRow: globalIndex }}></div>
            <div className="releases header" style={{ gridRow: globalIndex }}>
              # People on the Development Team:
            </div>
            {Array.from({ length: weeks }, (_, w) => (
              <div className="week" style={{ gridRow: globalIndex }} key={w + 1}>
                1
              </div>
            ))}
          </>

          <span style={{ display: 'none' }}>{globalIndex++}</span>

          <>
            <div className="features" style={{ gridRow: globalIndex }}></div>
            <div className="releases header" style={{ gridRow: globalIndex }}>
              Average Daily Team Velocity:
            </div>
            {Array.from({ length: weeks }, (_, w) => (
              <div className="week" style={{ gridRow: globalIndex }} key={w + 1}>
                1
              </div>
            ))}
          </>

          <span style={{ display: 'none' }}>{globalIndex++}</span>

          <>
            <FeaturesComponent features={features} index={globalIndex} />
            <div className="axis" style={{ gridRow: `${globalIndex} / ${globalIndex + 2}` }}></div>
            <WeeksComponent originalProjectStart={originalProjectStart} weeks={weeks} index={globalIndex} />
            <div className="key" style={{ gridRow: `${globalIndex}` }}>
              <div className="key backlog">Backlog</div>
              <div className="key in_progress">In Progress</div>
              <div className="key adequate">Adequate</div>
              <div className="key worrisome">Worrisome</div>
              <div className="key dangerous">Dangerous</div>
            </div>
          </>
        </div>
      </StyledWrapper>
    );
  }

  return null;
};

export default OnePageProjectManager;
