export const friendlyFirebaseMessage = (code) => {
  let newMessage = '';

  switch (code) {
    case 'auth/email-already-in-use':
      newMessage = 'This email is already in use. Please use another or try logging in';
      break;

    case 'auth/invalid-email':
      newMessage = 'The email or password is not correct';
      break;

    case 'auth/wrong-password':
      newMessage = 'The email or password is not correct';
      break;

    case 'auth/user-not-found':
      newMessage = 'There is no account with these details. Please register or get in touch';
      break;

    case 'auth/weak-password':
      newMessage = 'Your password is not strong enough';
      break;

    case 'auth/quota-exceeded':
      /** @todo - send email to AAD if we ever hit this so we know to increase the limit */
      newMessage = 'We have encountered an error. Please try again later';
      break;

    default:
      newMessage = 'We have encountered an error. Please try again later';
      break;
  }

  return newMessage;
};
