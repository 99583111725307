import { gql } from '@apollo/client';

export const PROJECTS_QUERY = gql`
  {
    projects {
      id
      title
      projectCode
    }
  }
`;

export const CREATE_SUPPORT_TICKET_MUTATION = gql`
  mutation createSupportTicket($subject: String!, $priority: String!, $comment: String!, $files: [Upload!]) {
    createSupportTicket(subject: $subject, priority: $priority, comment: $comment, files: $files) {
      key
    }
  }
`;
