import PropTypes from 'prop-types';
// import { SearchWrapper, SearchIcon, SearchInputBase } from './styles';
// import NotificationMenu from 'components/Menus/NotificationMenu';
import ProfileMenu from 'components/Menus/ProfileMenu';
import SettingsMenu from 'components/Menus/SettingsMenu';

const LoggedInMenu = ({ route, simple = false }) => (
  <>
    {!simple && (
      <>
        {/* <NotificationMenu /> */}
        <SettingsMenu />
      </>
    )}

    <ProfileMenu />
  </>
);

LoggedInMenu.propTypes = {
  route: PropTypes.object.isRequired,
  simple: PropTypes.bool
};

export default LoggedInMenu;
