import { useState } from 'react';
import { Form, Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, Typography } from '@mui/material';
import { StyledChip } from './styles';
import { Edit } from '@mui/icons-material';
import { validationSchema } from './validation';

const UpdateUserDetailsForm = ({ user: { firstname, lastname, email, verified } }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Formik
      initialValues={{
        firstname,
        lastname,
        email
      }}
      validationSchema={validationSchema}
      onSubmit={({ firstname, lastname, email }) => console.log(firstname, lastname, email)}
    >
      {() => (
        <Form noValidate>
          <Typography variant="body" component="p" gutterBottom>
            Use the form below to update the users details.
          </Typography>

          <Field
            component={TextField}
            variant="outlined"
            name="firstname"
            label="First name"
            margin="normal"
            required
            fullWidth
            disabled={!editMode}
          />
          <Field
            component={TextField}
            variant="outlined"
            name="lastname"
            label="Last name"
            margin="normal"
            required
            fullWidth
            disabled={!editMode}
          />
          <Field
            component={TextField}
            variant="outlined"
            name="email"
            label="Email"
            margin="normal"
            required
            fullWidth
            disabled
          />

          <Typography variant="h6" component="h6" sx={{ mt: 3 }}>
            Verification Status
          </Typography>
          <StyledChip
            label={verified ? 'Verified' : 'Not Verified'}
            variant="outlined"
            verified={verified ? verified.toString() : 'false'}
            sx={{ mt: 1 }}
          />

          <Typography variant="body" component="p" sx={{ mt: 3 }}>
            {!editMode ? (
              <Button variant="outlined" color="primary" startIcon={<Edit />} onClick={() => setEditMode(true)}>
                Edit
              </Button>
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            )}
          </Typography>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateUserDetailsForm;
