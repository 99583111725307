import { Link } from 'react-router-dom';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Root,
  Container,
  IntroColumn,
  CenterContent,
  Logo,
  PolicyLinks,
  StyledLink,
  IntroText,
  IntroTextMain
} from './styles';
import { policyLinks } from './data';
import * as ROUTES from 'constants/routes';

const PolicyMenu = ({ darkMode }) => (
  <PolicyLinks>
    {policyLinks.map(({ title, link, external }, index) => {
      return external ? (
        <StyledLink key={index} component="a" href={link} target="_blank" rel="noopener noreferrer" darkMode={darkMode}>
          {title}
        </StyledLink>
      ) : (
        <StyledLink key={index} component={Link} to={link} target="_blank" darkMode={darkMode}>
          {title}
        </StyledLink>
      );
    })}
  </PolicyLinks>
);

const AuthTemplate = ({ component: Component, route, title, description, lightState }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Root>
      <Container container spacing={0}>
        {!matches && (
          <IntroColumn item xs={12} lg={6}>
            <Grid item xs={10} lg={8}>
              <Link to={ROUTES.HOME}>
                <Logo />
              </Link>
              <IntroTextMain component="h1" gutterBottom variant="h1">
                {title}
              </IntroTextMain>
              <IntroText component="h2" variant="h4">
                {description}
              </IntroText>

              <PolicyMenu darkMode={false} />
            </Grid>
          </IntroColumn>
        )}

        <CenterContent item xs={12} lg={6}>
          <Grid item xs={10} lg={6}>
            {matches && <Logo darkMode={true} />}
            <Component route={route} lightState={lightState} />
            {matches && <PolicyMenu darkMode={true} />}
          </Grid>
        </CenterContent>
      </Container>
    </Root>
  );
};

export default AuthTemplate;
