import * as Yup from 'yup';

export const validationSchema = Yup.object({
  email: Yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  fname: Yup.string('Enter your first name').required('First name is required'),
  lname: Yup.string('Enter your last name').required('Last name is required'),
  password1: Yup.string('').min(8, 'Password must contain at least 8 characters').required('Enter your password'),
  password2: Yup.string('Enter your password')
    .required('Confirm your password')
    .oneOf([Yup.ref('password1')], 'Password does not match'),
  agree: Yup.boolean()
    .oneOf([true], 'You must agree to the terms & conditions')
    .required('You must agree to the terms & conditions')
});
