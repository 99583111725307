import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { LIST_ALL_AGENCIES_QUERY } from './data';
import Table from 'components/Table';
import StaticIntro from 'components/StaticIntro';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import * as ROUTES from 'constants/routes';

const UsersPage = () => {
  const { loading, error, data } = useQuery(LIST_ALL_AGENCIES_QUERY);

  return (
    <>
      <StaticIntro pretitle="Management" title="Agencies" />

      {loading && <LoadingCardWrapper page={true} />}
      {error && 'Currently unavailable'}
      {data && (
        <Grid container>
          <Grid item xs={12}>
            <Table
              title="Manage Users"
              columns={[
                { title: 'Name', field: 'name' },
                { title: 'Actions', field: 'agencyProfile' }
              ]}
              data={data.agencies.map(({ id, name }, index) => {
                return {
                  name,
                  agencyProfile: (
                    <Button
                      component={Link}
                      variant="outlined"
                      size="small"
                      to={`${ROUTES.DASHBOARD_SUPER_ADMIN_AGENCY_ROOT}/${id}`}
                    >
                      View
                    </Button>
                  )
                };
              })}
              options={{
                pageSize: data.agencies.length < 20 ? data.agencies.length : 20,
                sorting: true,
                draggable: false
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UsersPage;
