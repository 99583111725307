import { useDispatch, useSelector } from 'react-redux';
import { setUserLightMode } from 'reducers/typeReducer';
import { useMutation } from '@apollo/client';
import { LIGHT_MODE_MUTATION } from './data';
import { LightModeWrapper, StyledRadioButton } from './styles';

const LightModeToggle = () => {
  const dispatch = useDispatch();
  const { lightMode } = useSelector((state) => state.type);
  const [updateUserLightMode] = useMutation(LIGHT_MODE_MUTATION);

  const handleLightModeToggle = (type) => {
    const mode = type === 'light';

    dispatch(setUserLightMode({ lightMode: mode }));
    updateUserLightMode({ variables: { lightmode: mode } });
  };

  const renderButton = (type, active) => (
    <StyledRadioButton type={type} active={active} onClick={() => handleLightModeToggle(type)}>
      {['one', 'two', 'three', 'four'].map((box) => (
        <div key={box} className={`box box-${box}`}></div>
      ))}
    </StyledRadioButton>
  );

  return (
    <LightModeWrapper>
      {renderButton('light', lightMode)}
      {renderButton('dark', !lightMode)}
    </LightModeWrapper>
  );
};

export default LightModeToggle;
