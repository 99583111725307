import { Card, CardContent, Typography } from '@mui/material';
import AvatarGenerator from 'components/AvatarGenerator';

const ProjectClientDetailCard = ({ email, first_name, last_name, importance, phone }) => {
  return (
    <Card variant="outlined" sx={{ mb: 4 }}>
      <CardContent sx={{ textAlign: 'center', paddingTop: '28px' }}>
        <AvatarGenerator
          email={email}
          size={50}
          firstname={first_name}
          lastname={last_name}
          style={{ margin: '0 auto 6px' }}
        />
        {importance && (
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            {importance.toUpperCase()}
          </Typography>
        )}

        <Typography component="p" variant="body" gutterBottom>
          {first_name} {last_name}
        </Typography>

        {email && (
          <Typography component="p" variant="body2" gutterBottom>
            <a style={{ textDecoration: 'none' }} href={`mailto:${email}`} rel="noopener noreferrer">
              {email}
            </a>
          </Typography>
        )}

        {phone && (
          <Typography component="p" variant="body2" gutterBottom>
            {phone}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ProjectClientDetailCard;
