import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, FormControl, InputLabel, MenuItem, Select, Typography, Grid } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { StyledActionButtons } from './styles';
import { formatDuration } from 'helpers/content';
import Tabs from 'components/Tabs';
import Table from 'components/Table';
import ColouredBox from 'components/Box/ColouredBox';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import * as ROUTES from 'constants/routes';

const ProjectTasksCard = ({
  id,
  data,
  error,
  loading,
  setLoadingTable,
  setShowOpen,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  months,
  setMonths,
  selectedMonth,
  setSelectedMonth
}) => {
  const { role } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const { project } = data || {};
  let {
    tasks: { tasks, allowance }
  } = project || {};
  tasks = tasks || [];

  const generateStartDate = (month) => {
    if (moment(month, 'MMMM YYYY').isSame(moment(months[months.length - 1], 'MMMM YYYY'))) {
      const firstAllowanceDate = allowance.allowance.find((item) => item.date).date;
      const formattedDate = moment(firstAllowanceDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
      setStartDate(formattedDate);
    } else {
      setStartDate(moment(month, 'MMMM YYYY').startOf('month').format('YYYY/MM/DD'));
    }
  };

  const generateEndDate = (month) => {
    const currentMonthYear = moment().format('MMMM YYYY');
    if (moment(month, 'MMMM YYYY').isSame(moment(currentMonthYear, 'MMMM YYYY'))) {
      setEndDate(moment().format('YYYY/MM/DD'));
    } else {
      setEndDate(moment(month, 'MMMM YYYY').endOf('month').format('YYYY/MM/DD'));
    }
  };

  const handleMonthChange = (month) => {
    setLoadingTable(true);
    setSelectedMonth(month);

    // if month is current month set showopen
    if (moment(month, 'MMMM YYYY').isSame(moment(), 'month')) {
      setShowOpen(true);
    } else {
      setShowOpen(false);
    }

    generateStartDate(month);
    generateEndDate(month);
  };

  useEffect(() => {
    if (!loading && project) {
      const contractStartDate = project.contractStartDate
        ? moment.utc(project.contractStartDate, 'DD/MM/YYYY')
        : moment.utc().startOf('month');
      const formattedContractStartDate = moment(contractStartDate).format('MM-DD-YYYY');
      const start = moment().startOf('month');
      const end = moment(formattedContractStartDate, 'MM-DD-YYYY');

      let newMonths = [];
      let current = start;
      while (current.format('MMMM YYYY') !== end.format('MMMM YYYY')) {
        newMonths.push(current.format('MMMM YYYY'));
        current = current.subtract(1, 'month');
      }
      // need to add current one last time because the loop will have ended when it is equal to the end date, and will not have added it
      newMonths.push(current.format('MMMM YYYY'));
      setMonths(newMonths);

      // set selected month to current month only if it hasn't been set yet
      if (!selectedMonth) {
        setSelectedMonth(moment().format('MMMM YYYY'));
      }
    }
  }, [project, loading, setSelectedMonth, setMonths, selectedMonth]);

  let taskSummary = '';
  if (moment(endDate, 'YYYY/MM/DD').isBefore(moment(), 'month')) {
    taskSummary = `This list shows tasks that were completed between ${moment(startDate, 'YYYY/MM/DD').format(
      'DD/MM/YYYY'
    )} and ${moment(endDate, 'YYYY/MM/DD').format('DD/MM/YYYY')}`;
  } else {
    taskSummary = 'This list shows tasks that were completed or still open this month';
  }

  const minutesToReadableText = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (remainingMinutes === 0) {
      return `${hours}h`;
    } else {
      return `${hours}h ${remainingMinutes}m`;
    }
  };

  // Check if selectedMonth is the current month
  const isCurrentMonth = moment(selectedMonth, 'MMMM YYYY').isSame(moment(), 'month');

  let statusArray = ['To Do', 'In Progress'];
  let statusName = 'Open';

  if (tab === 1 || !isCurrentMonth) {
    statusArray = ['Done'];
    statusName = 'Completed';
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h2">
            <strong>{selectedMonth}</strong>
          </Typography>

          {taskSummary && (
            <Typography component="p" sx={{ mb: 4 }}>
              {taskSummary}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} style={{ justifyContent: 'end', display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <InputLabel id="select-month-label">Select Month</InputLabel>
            <Select
              labelId="select-month-label"
              id="select-month"
              value={selectedMonth}
              onChange={(e) => handleMonthChange(e.target.value)}
              label="Select Month"
              style={{ minWidth: '180px' }}
            >
              {months.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <ColouredBox
            number={minutesToReadableText(allowance.totalSpentMinutes)}
            title="Time Used"
            color="green"
            loading={loading}
            iconColor="white"
            size={34}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ColouredBox
            number={minutesToReadableText(allowance.totalRemainingMinutes)}
            title="Remaining"
            color="red"
            loading={loading}
            iconColor="white"
            size={34}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ColouredBox
            number={minutesToReadableText(allowance.totalAvailableMinutes)}
            title="Total Available"
            color="blue"
            loading={loading}
            iconColor="white"
            size={34}
          />
        </Grid>
      </Grid>

      {isCurrentMonth && <Tabs tab={tab} tabs={['Open', 'Completed']} handleTabChange={handleTabChange} />}

      <Table
        title={`${statusName} Tasks for ${selectedMonth}`}
        columns={
          loading
            ? [{ title: '', field: 'message', cellStyle: { textAlign: 'center' } }]
            : [
                { title: 'ID', field: 'id' },
                { title: 'Summary', field: 'summary' },
                { title: 'Status', field: 'status' },
                ...(role === 'superAdmin' ? [{ title: 'Time Estimate', field: 'timeEstimate' }] : []),
                ...(role === 'superAdmin' ? [{ title: 'Time Spent', field: 'timeSpent' }] : []),
                { field: 'actions', filtering: false }
              ]
        }
        data={
          loading
            ? [{ message: <LoadingSpinner /> }]
            : error
            ? [{ message: 'An error has occurred. Please try again later.' }]
            : tasks &&
              tasks
                .filter(({ fields }) => statusArray.includes(fields.status.statusCategory.name))
                .map(({ key, fields }) => {
                  return {
                    id: key,
                    summary: fields.summary,
                    status: fields.status.name,
                    ...(role === 'superAdmin' ? { timeEstimate: formatDuration(fields.timeestimate) } : {}),
                    ...(role === 'superAdmin' ? { timeSpent: formatDuration(fields.timespent) } : {}),
                    actions: (
                      <StyledActionButtons>
                        <Button variant="outlined" size="small" component={Link} to={`${ROUTES.DASHBOARD}/task/${key}`}>
                          View task
                        </Button>

                        {role === 'superAdmin' && (
                          <Button
                            component="a"
                            href={`https://andanotherday.atlassian.net/browse/${key}`}
                            target="_blank"
                            variant="outlined"
                            size="small"
                            style={{ marginLeft: '8px' }}
                            endIcon={<OpenInNew />}
                          >
                            Open in Jira
                          </Button>
                        )}
                      </StyledActionButtons>
                    )
                  };
                })
        }
        localization={{
          body: {
            emptyDataSourceMessage: 'There are currently no tasks for this project'
          }
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: tasks && tasks.length > 0 ? (tasks && tasks.length < 10 ? tasks && tasks.length : 10) : 5,
          sorting: loading || error ? false : true,
          draggable: false,
          filtering: false
        }}
      />
    </>
  );
};

/** Type checking */
ProjectTasksCard.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  setLoadingTable: PropTypes.func,
  setShowOpen: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  months: PropTypes.array,
  setMonths: PropTypes.func,
  selectedMonth: PropTypes.string
};

export default ProjectTasksCard;
