import { Box, Button } from '@mui/material';

const Tabs = ({ tab, tabs, handleTabChange, customTabs = false, sx }) => (
  <Box variant="contained" sx={{ mb: 2, ...sx }}>
    {tabs.map((tabName, index) => (
      <Button
        key={index}
        variant={tab === index ? 'outlined' : 'text'}
        onClick={() => handleTabChange(null, index)}
        sx={{ mr: 2 }}
      >
        {tabName}
      </Button>
    ))}
  </Box>
);

export default Tabs;
