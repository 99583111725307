import FormHeader from 'components/FormHeader';
import PasswordForgetForm from 'components/Forms/PasswordForgetForm';

const PasswordForgetPage = () => {
  return (
    <>
      <FormHeader
        forceColor="dark"
        title="Forgot your Password?"
        subtitle="Enter your email and we send you a password reset link."
      />
      <PasswordForgetForm />
    </>
  );
};

export default PasswordForgetPage;
