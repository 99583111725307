import Table from 'components/Table';
import { StyledChip } from 'components/SslChip/styles';

const ForecastingTable = ({ data }) => {
  const rows = data.forecasting.releases.map((release, index) => ({
    id: index,
    name: release.name,
    ...release,
    companyName: release?.invoice?.contact?.name,
    actions: `https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=${release?.invoice?.invoiceID}`
  }));

  return (
    <Table
      title="View Releases"
      columns={[
        { title: 'Name', field: 'name' },
        { title: 'Release Date', field: 'releaseDate' },
        { title: 'Released', field: 'released' },
        { title: 'Amount Paid', field: 'amountPaid' },
        { title: 'Amount Due', field: 'amountDue' },
        { title: 'Company Name', field: 'companyName' },
        { title: 'Actions', field: 'actions' }
      ]}
      data={
        rows &&
        rows.map((release, index) => {
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: release.invoice?.currencyCode || 'GBP'
          });

          return {
            name: release.name,
            releaseDate: release.releaseDate,
            released: (
              <StyledChip
                label={release.released ? 'Released' : 'Not Released'}
                size="small"
                variant="outlined"
                valid={release.released.toString()}
              />
            ),
            amountPaid: isNaN(release.invoice?.amountPaid) ? '-' : formatter.format(release.invoice?.amountPaid),
            amountDue: isNaN(release.invoice?.amountDue) ? '-' : formatter.format(release.invoice?.amountDue),
            companyName: release.companyName,
            actions: release?.invoice && (
              <a href={release.actions} target="_blank" rel="noopener noreferrer">
                See invoice
              </a>
            )
          };
        })
      }
      options={{
        pageSize: rows.length < 20 ? rows.length : 20,
        pageSizeOptions: [rows.length, 5, 10, 20],
        sorting: true,
        draggable: false
      }}
      style={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }}
    />
  );
};

export default ForecastingTable;
