import { styled } from '@mui/material/styles';
import { red, grey } from '@mui/material/colors';

export const StyledCode = styled('code')`
  background: ${grey[200]};
  border-radius: 4px;
  color: ${red[700]};
  display: inline-block;
  font-size: 1rem;
  margin-bottom: ${(p) => p.theme.spacing(2)};
  padding: ${(p) => p.theme.spacing(2)};
  width: 100%;
`;
