import { createSlice } from '@reduxjs/toolkit';

/** Initial state */
const initState = {
  loginError: null,
  signupError: null,
  signoutError: null
};

const errorSlice = createSlice({
  name: 'error',
  initialState: initState,
  reducers: {
    setLoginError: (state, action) => {
      state.loginError = action.payload;
    },
    setSignupError: (state, action) => {
      state.signupError = action.payload;
    },
    setSignoutError: (state, action) => {
      state.signoutError = action.payload;
    },
    setVerifyError: (state, action) => {
      state.verifyError = action.payload;
    }
  }
});

export const { setLoginError, setSignupError, setSignoutError, setVerifyError } = errorSlice.actions;

export default errorSlice.reducer;
