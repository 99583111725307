import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Toolbar } from '@mui/material';
import { StyledAppBar, AppBarSpacer, Grow } from './styles';
import LoggedInMenu from 'components/Menus/LoggedInMenu';
import LoggedOutMenu from 'components/Menus/LoggedOutMenu';

const Header = ({ route, intro }) => {
  const { loggedIn, email } = useSelector((state) => state.auth);
  const { open } = useSelector((state) => state.menu);
  const { lightMode } = useSelector((state) => state.type);

  return (
    <>
      <StyledAppBar lightMode={lightMode} open={open}>
        <Toolbar>
          {intro}
          <Grow />
          {loggedIn ? <LoggedInMenu route={route} email={email} /> : <LoggedOutMenu />}
        </Toolbar>
      </StyledAppBar>

      <AppBarSpacer />
    </>
  );
};

/** Type checking */
Header.propTypes = {
  route: PropTypes.object.isRequired,
  intro: PropTypes.node
};

export default Header;
