import { useSelector } from 'react-redux';
import SupportSidebar from 'views/Dashboard/Support/SupportSidebar';
import SupportTicketsCard from 'components/Cards/SupportTicketsCard';

const SupportTicketLists = ({ status }) => {
  const {
    support: { openCount, closedCount }
  } = useSelector((state) => state.auth);

  return (
    <>
      <SupportSidebar openCount={openCount} closedCount={closedCount} />
      <SupportTicketsCard status={status} border="false" search={true} ticketsToShow={20} />
    </>
  );
};

export default SupportTicketLists;
