import { useState } from 'react';
import { Divider, IconButton, Typography } from '@mui/material';
import { Tune } from '@mui/icons-material';
import { StyledDrawer } from './styles';
import LightModeToggle from 'components/Forms/LightModeToggle';

const SettingsMenu = () => {
  const [settingsOpen, setSettingsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setSettingsOpen(open);
  };

  return (
    <>
      <IconButton onClick={toggleDrawer(true)} style={{ marginLeft: '8px' }}>
        <Tune />
      </IconButton>
      <StyledDrawer anchor="right" open={Boolean(settingsOpen)} onClose={toggleDrawer(false)}>
        <Typography>Settings</Typography>
        <Divider style={{ margin: '16px 0 32px' }} />
        <LightModeToggle />
      </StyledDrawer>
    </>
  );
};

export default SettingsMenu;
