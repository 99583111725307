import { useSelector } from 'react-redux';
import { Button, Grid, Typography, Box } from '@mui/material';
import { ErrorMessage } from './styles';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';

const ErrorPage = ({ error, resetErrorBoundary }) => {
  const { role } = useSelector((state) => state.auth);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <DefaultCardWrapper title="An Error has Occurred" sx={{ mt: 8 }}>
          {role === 'superAdmin' ? (
            <>
              <Typography component="p" variant="body1" gutterBottom>
                Something went wrong. Below is the stack trace:
              </Typography>
              <ErrorMessage>{error.stack}</ErrorMessage>
            </>
          ) : (
            <Typography component="p" variant="body1" gutterBottom>
              Something went wrong while trying to this page. Please try again later or contact an administrator.
            </Typography>
          )}
          <Box mt={2}>
            <Button variant="outlined" onClick={resetErrorBoundary}>
              Go back
            </Button>
          </Box>
        </DefaultCardWrapper>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
