import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HeaderLogo } from './styles';
import * as ROUTES from 'constants/routes';

const Logo = () => {
  const lightMode = useSelector((state) => state.type.lightMode);

  return (
    <Link to={ROUTES.HOME}>
      <HeaderLogo lightMode={lightMode} />
    </Link>
  );
};

export default Logo;
