import { useSelector } from 'react-redux';
import { Grid, Tab } from '@mui/material';
import { Switch, Route, Link, BrowserRouter } from 'react-router-dom';
import { StyledTabs } from './styles';
import StaticIntro from 'components/StaticIntro';
import UpdateUserDetailsForm from 'components/Forms/UpdateUserDetailsForm';
import UpdateUserPasswordForm from 'components/Forms/UpdateUserPasswordForm';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';
import * as ROUTES from 'constants/routes';

const Settings = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Grid container spacing={4}>
        <Grid item xs={12} md={2}></Grid>

        <Grid item xs={12} md={8}>
          <StaticIntro pretitle="Account" title="Update Your Profile">
            Update account details and relevant forms will be here
          </StaticIntro>

          <Route
            path="/"
            render={({ location }) => (
              <>
                <StyledTabs indicatorColor="primary" value={location.pathname}>
                  <Tab
                    label="General"
                    component={Link}
                    to={ROUTES.DASHBOARD_ACCOUNT}
                    value={ROUTES.DASHBOARD_ACCOUNT}
                  />
                  <Tab
                    label="Security"
                    component={Link}
                    to={ROUTES.DASHBOARD_ACCOUNT_SECURITY}
                    value={ROUTES.DASHBOARD_ACCOUNT_SECURITY}
                  />
                  <Tab
                    label="Notifications"
                    component={Link}
                    to={ROUTES.DASHBOARD_ACCOUNT_NOTIFICATIONS}
                    value={ROUTES.DASHBOARD_ACCOUNT_NOTIFICATIONS}
                  />
                  <Tab
                    label="Billing"
                    component={Link}
                    to={ROUTES.DASHBOARD_ACCOUNT_BILLING}
                    value={ROUTES.DASHBOARD_ACCOUNT_BILLING}
                  />
                </StyledTabs>

                <Switch>
                  <Route
                    path={ROUTES.DASHBOARD_ACCOUNT_SECURITY}
                    render={() => (
                      <DefaultCardWrapper title="Update your password">
                        <UpdateUserPasswordForm />
                      </DefaultCardWrapper>
                    )}
                  />

                  <Route
                    path={ROUTES.DASHBOARD_ACCOUNT_NOTIFICATIONS}
                    render={() => <DefaultCardWrapper title="Notifications">Coming soon...</DefaultCardWrapper>}
                  />

                  <Route
                    path={ROUTES.DASHBOARD_ACCOUNT_BILLING}
                    render={() => <DefaultCardWrapper title="Billing">Coming soon...</DefaultCardWrapper>}
                  />

                  <Route
                    path={ROUTES.DASHBOARD_ACCOUNT}
                    render={() => (
                      <DefaultCardWrapper title="Your Details">
                        <UpdateUserDetailsForm user={auth} />
                      </DefaultCardWrapper>
                    )}
                  />
                </Switch>
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} md={2}></Grid>
      </Grid>
    </BrowserRouter>
  );
};

export default Settings;
