import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import SupportSidebar from 'views/Dashboard/Support/SupportSidebar';
import SupportTicketForm from 'components/Forms/SupportTicketForm';

const SupportCreate = ({ extraSidebar }) => {
  const {
    support: { openCount, closedCount }
  } = useSelector((state) => state.auth);

  return (
    <>
      <SupportSidebar openCount={openCount} closedCount={closedCount} />

      <Grid container>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <SupportTicketForm />
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
    </>
  );
};

export default SupportCreate;
