import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Grid, Typography, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { GET_USER_QUERY } from './data';
import StaticIntro from 'components/StaticIntro';
import UserManagementActions from './UserManagementActions';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';
import UpdateUserDetailsForm from 'components/Forms/UpdateUserDetailsForm';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import * as ROUTES from 'constants/routes';

const UsersDetailsPage = ({ route }) => {
  const { uid } = useParams();
  const { loading, error, data } = useQuery(GET_USER_QUERY, { variables: { uid } });

  return (
    <>
      <StaticIntro pretitle="Management" title="Edit User" />

      {loading && <LoadingCardWrapper page={true} />}

      {error && (
        <>
          <Typography gutterBottom>This user does not exist. Please return to the users list.</Typography>
          <Button component={Link} to={ROUTES.DASHBOARD_SUPER_ADMIN_USERS} variant="contained" color="primary">
            Go back to customer list
          </Button>
        </>
      )}

      {data && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <DefaultCardWrapper title="Update User" sx={{ mb: 5 }}>
              <UpdateUserDetailsForm user={data.user} />
            </DefaultCardWrapper>

            <DefaultCardWrapper title="User Details">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">Created</TableCell>
                    <TableCell>{data.user.metadata.creationTime}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">Last sign in</TableCell>
                    <TableCell>{data.user.metadata.lastSignInTime}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DefaultCardWrapper>
          </Grid>

          <Grid item xs={12} md={6}>
            <DefaultCardWrapper title="Invoices/Billing">Coming soon...</DefaultCardWrapper>

            <Grid container spacing={4} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <DefaultCardWrapper title="Send Emails">Coming soon...</DefaultCardWrapper>
              </Grid>

              <Grid item xs={12} md={6}>
                <UserManagementActions uid={data.user.uid} email={data.user.email} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UsersDetailsPage;
