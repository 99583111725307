import { gql } from '@apollo/client';

export const GET_AGENCY_QUERY = gql`
  query agency($id: ID!) {
    agency(id: $id) {
      id
      name
    }
  }
`;
