import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as LogoSvg } from '../../assets/img/full-logo.svg';

export const Root = styled('div')`
  flex-grow: 1;
`;

export const Container = styled(Grid)`
  height: 100vh;

  ${(p) => p.theme.breakpoints.down('lg')} {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const IntroColumn = styled(Grid)`
  background-color: ${(p) => p.theme.palette.common.black};
  padding: ${(p) => p.theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CenterContent = styled(Grid)`
  background: ${(p) => p.theme.palette.common.white};
  padding: ${(p) => p.theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled(({ darkMode = false, ...other }) => <LogoSvg {...other} />)`
  fill: ${(p) => (p.darkMode ? p.theme.palette.common.black : p.theme.palette.common.white)};
  position: absolute;
  top: 120px;
  height: 60px;

  ${(p) => p.theme.breakpoints.down('lg')} {
    display: block;
    margin: 0 auto 20px;
    position: relative;
    top: auto;
  }
`;

export const PolicyLinks = styled('div')`
  bottom: 120px;
  position: absolute;

  ${(p) => p.theme.breakpoints.down('lg')} {
    font-size: 12px;
    position: relative;
    bottom: auto;
    margin-top: ${(p) => p.theme.spacing(3)};
  }
`;

export const StyledLink = styled(({ darkMode, component: Component, ...other }) => <Component {...other} />)`
  border-right: 1px solid ${(p) => (p.darkMode ? p.theme.palette.common.black : p.theme.palette.common.white)};
  color: ${(p) => (p.darkMode ? p.theme.palette.common.black : p.theme.palette.common.white)};
  margin-right: ${(p) => p.theme.spacing(2)};
  padding-right: ${(p) => p.theme.spacing(2)};
  text-decoration: none;

  &:last-child {
    border: none;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const IntroText = styled(Typography)`
  color: ${(p) => p.theme.palette.common.white} !important;

  ${(p) => p.theme.breakpoints.down('lg')} {
    font-size: min(20vw, 24px);
  }
`;

export const IntroTextMain = styled(Typography)`
  color: ${(p) => p.theme.palette.common.white} !important;

  ${(p) => p.theme.breakpoints.down('lg')} {
    font-size: min(20vw, 54px);
  }
`;
