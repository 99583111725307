import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const Root = styled('div')`
  display: flex;
`;

export const Main = styled(({ open, extraSidebar, ...other }) => <main {...other} />)`
  left: auto;
  min-height: 100vh;
  position: absolute;
  right: 10px;
  transition: ${(p) =>
    p.theme.transitions.create(['width', 'left'], {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.leavingScreen
    })} !important;
  width: ${(p) => (!p.open ? `calc(100% - 110px)` : `calc(100% - 260px)`)} !important;

  @media (max-width: 600px) {
    left: 0 !important;
    width: 100% !important;
  }
`;

export const MainInner = styled('div')`
  min-height: 400px;
  padding: ${(p) => (p.extraSidebar ? 0 : p.theme.spacing(4))};
  position: relative;
`;

export const ContactButton = styled(Button)`
  margin: ${(p) => p.theme.spacing(2)} auto 0 !important;
`;

export const Toolbar = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${(p) => p.theme.spacing(0, 1)};
  ${(p) => p.theme.mixins.toolbar};
`;
