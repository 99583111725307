import { styled } from '@mui/material/styles';
import { Button, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[300]};
  margin: ${(p) => p.theme.spacing(5)} 0 ${(p) => p.theme.spacing(5)};
`;

export const SubmitButton = styled(Button)`
  margin-top: ${(p) => p.theme.spacing(2)} !important;
`;

export const IconButton = styled(Button)`
  svg {
    margin-right: 6px;
  }
`;
