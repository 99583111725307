import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';

const AgencyDetailsTable = ({ name }) => {
  return (
    <DefaultCardWrapper title="Agency Details">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell scope="row">Name</TableCell>
            <TableCell>{name}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DefaultCardWrapper>
  );
};

export default AgencyDetailsTable;
