import { authRef } from 'config/firebase';
import { signoutSuccess } from 'reducers/authReducer';
import { setSignoutError } from 'reducers/errorReducer';

export const doSignOut = () => (dispatch) => {
  return authRef
    .signOut()
    .then((result) => {
      dispatch(signoutSuccess());
    })
    .catch((error) => {
      dispatch(setSignoutError('Error signing out. Please try again.'));
    });
};
