import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Box, Button, Grid, Typography } from '@mui/material';
import { SUPPORT_CLIENTS_QUERY } from 'components/Cards/SupportClientsCard/data';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import ProjectCard from 'components/Cards/ProjectCard';
import StaticIntro from 'components/StaticIntro';

const ProjectGrid = ({ projects, personal }) => (
  <>
    {!projects && (
      <Typography gutterBottom>
        <strong>You have no {personal ? 'personal ' : ''}projects.</strong>
      </Typography>
    )}

    <Grid container spacing={4}>
      {typeof projects !== 'undefined' &&
        projects &&
        projects.length > 0 &&
        [...projects]
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((project, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <ProjectCard project={project} />
            </Grid>
          ))}
    </Grid>
  </>
);

const Projects = () => {
  const { agencies, projects, role } = useSelector((state) => state.auth);
  const { loading, error, data } = useQuery(SUPPORT_CLIENTS_QUERY, {
    variables: { type: 'support' },
    skip: role !== 'superAdmin'
  });

  /**
   * If a project is part of an agency and is also personal we don't want to display it twice.
   * So we can filter it out of personal.
   */
  const filteredProjects =
    projects &&
    [...projects].filter((project) =>
      []
        .concat(
          ...(agencies
            ? agencies.map(({ projects }) => projects && projects.filter(({ id }) => id === project.id))
            : [])
        )
        .filter((item) => item !== null).length
        ? false
        : project
    );

  return (
    <>
      <StaticIntro pretitle="Dashboard / Projects" title="All Your Projects" />

      {(!agencies || agencies.length === 0) && (!projects || projects.length === 0) && (
        <Box mb={4}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ mb: 2 }}>
            You have no projects set up with AndAnotherDay.
          </Typography>
          <Typography gutterBottom sx={{ mb: 2 }}>
            Please get in touch with us to start your first project or let us help with an existing project.
          </Typography>
          <Typography gutterBottom>
            <Button component="a" variant="outlined" href="mailto:ideas@andanotherday.com">
              Email us to get started
            </Button>
          </Typography>
        </Box>
      )}

      {agencies &&
        agencies.length > 0 &&
        [...agencies]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ name, projects }, index) => (
            <Box mb={4} key={index}>
              <Typography variant="h5" component="h2">
                {name}
              </Typography>
              <Typography sx={{ mb: 2 }}>Projects owned by, or shared with {name} will appear below.</Typography>
              <ProjectGrid projects={projects} />
            </Box>
          ))}

      {filteredProjects && filteredProjects.length > 0 && (
        <Box mb={4}>
          <Typography variant="h5" component="h2">
            Personal Projects
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Your personal projects are available below. If you share a personal project with an agency, the project wil
            be listed under that agency.
          </Typography>
          <ProjectGrid projects={filteredProjects} personal={true} />
        </Box>
      )}

      {loading && role === 'superAdmin' && <LoadingCardWrapper />}
      {error && null}
      {data && role === 'superAdmin' && (
        <Box>
          <Typography variant="h5" component="h2">
            Support Client Projects
          </Typography>
          <Typography sx={{ mb: 2 }}>Below is the list of support client projects.</Typography>
          <ProjectGrid projects={data.projects} personal={true} />
        </Box>
      )}
    </>
  );
};

export default Projects;
