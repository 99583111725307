import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

export const StyledPaper = styled(Paper)`
  padding: 0 !important;
`;

export const StyledPaperInner = styled('div')`
  padding: ${(p) => p.theme.spacing(2)};
`;
