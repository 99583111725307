import { gql } from '@apollo/client';

export const TIME_TRACKING = gql`
  query TimeTracking($startDate: String!, $endDate: String!) {
    timeTracking(startDate: $startDate, endDate: $endDate) {
      user {
        email
        firstname
        lastname
      }
      totalSpentSeconds
      workingTimeSeconds
      worklogs {
        task {
          key
          fields {
            summary
            status {
              statusCategory {
                name
              }
            }
            project {
              name
              key
            }
          }
        }
        timeSpentSeconds
        started
      }
    }
  }
`;
