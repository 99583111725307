import { createSlice } from '@reduxjs/toolkit';

/** Initial state */
const initState = {
  lightMode: null
};

const typeSlice = createSlice({
  name: 'type',
  initialState: initState,
  reducers: {
    setUserLightMode: (state, action) => {
      state.lightMode = action.payload.lightMode;
    }
  }
});

export const { setUserLightMode } = typeSlice.actions;

export default typeSlice.reducer;
