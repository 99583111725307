import { createSlice } from '@reduxjs/toolkit';

/** Initial state */
const initState = {
  open: true,
  subMenuOpen: false
};

const menuSlice = createSlice({
  name: 'menu',
  initialState: initState,
  reducers: {
    menuToggle: (state, action) => {
      state.open = action.payload.open;
    }
  }
});

export const { menuToggle } = menuSlice.actions;

export default menuSlice.reducer;
