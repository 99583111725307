import SimpleCard from '../SimpleCard';
import * as ROUTES from 'constants/routes';

const TicketCreationCard = (props) => {
  let id = props.project ? `?project=${props.project.id}` : '';

  return (
    <SimpleCard
      title="Create a Support Ticket"
      description="Click the button below to create a new support ticket"
      link={`${ROUTES.DASHBOARD_SUPPORT_CREATE}${id}`}
      linkText="Create ticket"
    />
  );
};

export default TicketCreationCard;
