import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { StickyHeader } from './styles';

const PopInHeader = ({ children, position = 150 }) => {
  const { open } = useSelector((state) => state.menu);
  const [scrollY, setScrollY] = useState(0);

  const logit = () => setScrollY(window.pageYOffset);

  useEffect(() => {
    const watchScroll = () => window.addEventListener('scroll', logit);
    watchScroll();

    return () => window.removeEventListener('scroll', logit);
  });

  return (
    <StickyHeader isSticky={scrollY >= position ? true : false} open={open}>
      {children}
    </StickyHeader>
  );
};

/** Type checking */
PopInHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  position: PropTypes.number.isRequired
};

export default PopInHeader;
