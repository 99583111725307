import DeploymentTable from '../DeploymentTable';
import DefaultCardWrapper from 'components/Wrappers/DefaultCardWrapper';

const DeploymentCards = ({ projects }) =>
  projects &&
  projects.map((project, index) => (
    <DefaultCardWrapper key={`deployment-card-${index}`} title={project.name} sx={{ mb: 4 }}>
      <DeploymentTable index={index} project={project} />
    </DefaultCardWrapper>
  ));

export default DeploymentCards;
