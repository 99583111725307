import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { BrokenImage } from '@mui/icons-material';
import { StyledCardMedia, NoImageWrapper } from './styles';
import { GET_PROJECT_QUERY } from './data';
import StaticIntro from 'components/StaticIntro';
import ProjectDetailsTable from './ProjectDetailsTable';
import ProjectMonitoringCard from 'components/Cards/ProjectMonitoringCard';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import * as ROUTES from 'constants/routes';

const ProjectDetailsPage = () => {
  const { id } = useParams();

  const updateProjectImage = () => {
    console.log('Updating image...');
  };

  const { loading, error, data } = useQuery(GET_PROJECT_QUERY, { variables: { id } });

  const project = data?.project;

  return (
    <>
      <StaticIntro pretitle="Management" title="Project Overview" />

      {loading ? (
        <LoadingCardWrapper page={true} />
      ) : error ? (
        <>
          <Typography gutterBottom>
            We have encountered an issue trying to fetch this project. Please return to the project list.
          </Typography>
          <Button component={Link} to={ROUTES.DASHBOARD_SUPER_ADMIN_PROJECTS} variant="contained" color="primary">
            Go back to project list
          </Button>
        </>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={8}>
            <ProjectDetailsTable data={data} style={{ marginBottom: '32px' }} />

            <ProjectMonitoringCard monitors={project.monitors} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Card style={{ marginBottom: '32px' }}>
              <CardActionArea onClick={updateProjectImage}>
                {project.screenshot.secure_url ? (
                  <StyledCardMedia
                    image={project.screenshot.secure_url.replace(/(\/upload\/)(v\d+\/)/, '$1c_fill,h_724,w_1528/$2')}
                    title={`${project.title} screenshot`}
                  />
                ) : (
                  <NoImageWrapper>
                    <BrokenImage />
                  </NoImageWrapper>
                )}
              </CardActionArea>
              <CardContent style={{ paddingBottom: '16px' }}>
                <Typography color="textSecondary" component="p">
                  Click on the cover image above to update it.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProjectDetailsPage;
