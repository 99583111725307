import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { List, ListItemIcon, Tooltip } from '@mui/material';
import { StyledListItem, StyledListItemText, MenuTitle } from '../DashboardMenu/styles';

const AdminMenu = ({ navigation }) => {
  const { open } = useSelector((state) => state.menu);
  const { features } = useSelector((state) => state.auth);

  return (
    <>
      {navigation.map(({ route, icon, text, children }, index) => (
        <Fragment key={index}>
          {open && <MenuTitle>{text}</MenuTitle>}

          <List component="nav" disablePadding>
            {children.map(({ route, icon, text, type, children, label, featureFlag }, index) => {
              if (featureFlag && features && !features.includes(featureFlag)) {
                return null;
              }

              return (
                <Tooltip key={index} title={!open ? label : ''} placement="right">
                  <StyledListItem button exact component={NavLink} to={route}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <StyledListItemText primary={text} />
                  </StyledListItem>
                </Tooltip>
              );
            })}
          </List>
        </Fragment>
      ))}
    </>
  );
};

export default AdminMenu;
