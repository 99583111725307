import { Grid, Typography, Paper } from '@mui/material';
import { BottomGap } from './styles';
import ProjectLeadCard from 'components/Cards/ProjectLeadCard';
import ProjectClientDetailCard from 'components/Cards/ProjectClientDetailCard';
import SupportFormCard from 'components/Cards/SupportFormCard';
import ProjectOverview from 'components/Steppers/ProjectOverview';

const OverviewPage = ({ project }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            {project?.summary && (
              <>
                <Typography component="h4" variant="h4" sx={{ mb: 2 }}>
                  <strong>Project Summary</strong>
                </Typography>

                <Typography component="p" variant="body2" gutterBottom sx={{ mb: 4 }}>
                  {project.summary}
                </Typography>
              </>
            )}

            <Typography component="h5" variant="h5" sx={{ mb: 3 }}>
              <strong>Project Process Explained</strong>
            </Typography>

            <ProjectOverview />
          </Grid>

          <Grid item xs={12} md={4}>
            {project?.projectLead && (
              <>
                <Typography variant="h6" component="h6" gutterBottom>
                  Project Lead
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ProjectLeadCard projectLead={project.projectLead} />
                  </Grid>
                </Grid>
              </>
            )}

            {project?.clientDetails && (
              <>
                <Typography variant="h6" component="h6" gutterBottom>
                  Contacts
                </Typography>

                <Grid container spacing={2}>
                  {project.clientDetails.map((client, index) => (
                    <Grid item xs={6} key={index}>
                      <ProjectClientDetailCard {...client} />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <BottomGap>
              <Paper>{project && <SupportFormCard align="left" single={project.projectCode} />}</Paper>
            </BottomGap>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OverviewPage;
