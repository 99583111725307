export const policyLinks = [
  {
    title: 'Terms of use',
    link: 'https://andanotherday.com/about/terms-of-use/',
    external: true
  },
  {
    title: 'Privacy Policy',
    link: 'https://andanotherday.com/about/policies/privacy-policy/',
    external: true
  }
];
