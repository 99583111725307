import { gql } from '@apollo/client';

export const GET_USER_QUERY = gql`
  {
    user {
      firstname
      lastname
      email
      role
      verified
      projectCount
      lightMode
      features
      agencies {
        id
        name
        projects {
          id
          title
          summary
          projectType
          websiteUrl
          screenshot {
            secure_url
          }
        }
      }
      projects {
        id
        title
        summary
        projectType
        websiteUrl
        screenshot {
          secure_url
        }
      }
      support {
        openCount
        closedCount
      }
    }
  }
`;
