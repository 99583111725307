import { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, CardContent, Chip, Stack, Typography } from '@mui/material';
import { ControlledBoard } from '@caldwell619/react-kanban';
import { chipColor } from 'helpers/chip';
import { formatDuration } from 'helpers/content';
import { NoWrapStrong } from './styles';

const convertWorklogsToBoard = (worklogs, startDate, endDate) => {
  // Ensure startDate and endDate are moment objects
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);

  // Create a range of dates from startDate to endDate, including weekends
  const dateRange = [];
  let currentDate = startDateMoment.clone();
  while (currentDate.isSameOrBefore(endDateMoment)) {
    dateRange.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'days');
  }

  // Group worklogs by date
  const groupedWorklogs = worklogs.reduce((acc, worklog) => {
    const date = moment(worklog.started).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(worklog);
    return acc;
  }, {});

  // Ensure each date in the range has an entry in groupedWorklogs
  dateRange.forEach((date) => {
    if (!groupedWorklogs[date]) {
      groupedWorklogs[date] = [];
    }
  });

  // Create columns for each date in the range
  const columns = dateRange
    .sort((a, b) => new Date(a) - new Date(b))
    .map((date, columnIndex) => {
      const cards = (groupedWorklogs[date] || []).map((worklog, cardIndex) => ({
        id: cardIndex + 1,
        summary: worklog.task.fields.summary,
        key: worklog.task.key,
        project: worklog.task.fields.project.name,
        timeSpentSeconds: worklog.timeSpentSeconds,
        statusCategory: worklog.task.fields.status.statusCategory.name
      }));

      const columnTotal = cards.reduce((total, card) => total + card.timeSpentSeconds, 0);

      return {
        id: columnIndex + 1,
        title: moment(date).format('dddd Do MMMM YYYY'),
        columnTotal: formatDuration(columnTotal),
        cards: cards
      };
    });

  return { columns };
};

const TimeTrackingTable = ({ worklogs, startDate, endDate }) => {
  const [board, setBoard] = useState({ columns: [] });

  useEffect(() => {
    const boardData = convertWorklogsToBoard(worklogs, startDate, endDate);
    setBoard(boardData);
  }, [worklogs, startDate, endDate]);

  return (
    <>
      <style jsx="true">
        {`
          .react-kanban-column {
            padding: 15px;
            border-radius: 2px;
            background-color: #000000;
            margin: 5px;
          }
          .react-kanban-column div {
            width: 100%;
          }
        `}
      </style>

      <ControlledBoard
        allowAddCard={false}
        allowRemoveCard={false}
        allowRemoveColumn={false}
        disableColumnDrag={true}
        disableCardDrag={true}
        allowRenameColumn={false}
        renderColumnHeader={({ title, columnTotal }) => (
          <>
            <Typography component="h6" variant="h6">
              <strong>{title}</strong>
            </Typography>
            <Typography component="p" variant="p" sx={{ mb: 2 }}>
              {columnTotal}
            </Typography>
          </>
        )}
        renderCard={({ summary, key, project, timeSpentSeconds, statusCategory }) => (
          <Card sx={{ maxWidth: '280px', width: '100%' }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {project}
              </Typography>
              <Typography variant="h6" component="h6" sx={{ fontSize: 16, lineHeight: 1.3 }}>
                {summary}
              </Typography>
              <Stack direction="row" spacing={3} justifyContent="space-between" sx={{ mt: 1.5 }}>
                <a href={`https://andanotherday.atlassian.net/browse/${key}`} target="_blank" rel="noopener noreferrer">
                  <Chip sx={{ width: 'auto !important' }} size="small" label={key} />
                </a>

                <Chip
                  size="small"
                  variant="outlined"
                  label={statusCategory}
                  sx={{ width: 'auto !important' }}
                  color={chipColor(statusCategory)}
                />

                <Typography variant="body2">
                  <NoWrapStrong>{formatDuration(timeSpentSeconds)}</NoWrapStrong>
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        )}
      >
        {board}
      </ControlledBoard>
    </>
  );
};

export default TimeTrackingTable;
