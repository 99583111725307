import { useState } from 'react';
import SslDialog from 'components/Dialogs/SslDialog';
import { StyledChip } from './styles';

const SslChip = ({ ssl, title }) => {
  const [open, setOpen] = useState(false);

  if (ssl && ssl.valid) {
    return <StyledChip label={ssl.message} size="small" variant="outlined" valid="true" />;
  }

  return (
    <>
      <StyledChip
        label="Invalid: Investigation needed"
        size="small"
        variant="outlined"
        valid="false"
        onClick={() => setOpen(true)}
      />
      <SslDialog title={title} message={ssl.message} open={open} setOpen={setOpen} />
    </>
  );
};

export default SslChip;
