import client from 'config/graphql';
import { authRef } from 'config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
// import { history } from 'helpers/History';
import { CREATE_USER_QUERY } from './data';
import { signupSuccess } from 'reducers/authReducer';
import { setSignupError } from 'reducers/errorReducer';
import { friendlyFirebaseMessage } from 'helpers/firebaseMessages';
import { doSignInWithEmailAndPassword } from 'actions/auth/signIn';
// import * as ROUTES from 'constants/routes';

export const doCreateUserWithEmailAndPassword = (firstname, lastname, email, password) => (dispatch) => {
  return createUserWithEmailAndPassword(authRef, email, password)
    .then(async () => {
      const user = authRef.currentUser;

      /** Use the current user ID to set/get roles on backend */
      await client
        .mutate({
          mutation: CREATE_USER_QUERY,
          variables: {
            firstname,
            lastname,
            email: user.email,
            uid: user.uid
          }
        })
        .then(({ data }) => {
          const {
            email,
            role,
            verified,
            firstname,
            lastname,
            projectCount,
            agencies,
            projects,
            support
          } = data.createUser;

          /** Once the backend end is updated call reducer */
          dispatch(
            signupSuccess({
              firstname,
              lastname,
              email,
              verified,
              role,
              projectCount,
              agencies,
              projects,
              support
            })
          );

          // Sign the user in
          dispatch(doSignInWithEmailAndPassword(email, password));
        })
        .catch(({ code }) => {
          dispatch(setSignupError(friendlyFirebaseMessage(code)));
        });
    })
    .catch(({ code }) => {
      dispatch(setSignupError(friendlyFirebaseMessage(code)));
    });
};
