import moment from 'moment';

export const FeaturesComponent = ({ features, index }) => {
  return features.map((feature, i) => (
    <div className="feature" key={i} style={{ gridRow: `${index} / ${index + 2}` }}>
      <div className="rotateText">
        <span>{feature.name}</span>
      </div>
    </div>
  ));
};

export const WeeksComponent = ({ originalProjectStart, weeks, index }) => {
  const datefrom = moment(originalProjectStart, 'YYYY-MM-DD');
  const weekElements = [];

  for (let we = 1; we <= weeks; we++) {
    const week_start = datefrom.clone().add(we - 1, 'weeks');
    const formattedDate = week_start.format('DD/MM/YYYY');

    weekElements.push(
      <div className="week" style={{ gridRow: `${index} / ${index}` }} key={we}>
        <div className="rotateText">
          <span>{formattedDate}</span>
        </div>
      </div>
    );
  }

  return <>{weekElements}</>;
};
