import { gql } from '@apollo/client';

export const SUPPORT_CLIENTS_QUERY = gql`
  query projects($type: String) {
    projects(type: $type) {
      id
      title
      summary
      websiteUrl
      supportClient
      screenshot {
        secure_url
      }
    }
  }
`;
