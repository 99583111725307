import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { SUPPORT_CLIENTS_QUERY } from './data';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import SupportProjectsListCard from 'components/Cards/SupportProjectsListCard';

const SupportClients = ({ setSupportProjectCount }) => {
  const { role } = useSelector((state) => state.auth);
  const { loading, error, data } = useQuery(SUPPORT_CLIENTS_QUERY, {
    variables: { type: 'support' },
    skip: role !== 'superAdmin'
  });

  useEffect(() => {
    if (data && role === 'superAdmin') {
      setSupportProjectCount(data.projects.length || 0);
    }
  }, [data, role, setSupportProjectCount]);

  if (loading) {
    return <LoadingCardWrapper />;
  }

  if (error) return 'Currently unavailable';

  if (role !== 'superAdmin') {
    return null;
  }

  const { projects } = data || {};

  return <SupportProjectsListCard projects={projects} />;
};

export default SupportClients;
