import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Divider, Typography } from '@mui/material';
import { StyledPaper, StyledPaperInner } from './styles';

const SimpleCard = ({ title, description, link, linkText }) => (
  <StyledPaper>
    <StyledPaperInner>
      <Typography variant="h6" component="h2">
        {title}
      </Typography>
    </StyledPaperInner>
    <Divider />
    <StyledPaperInner>
      <Typography gutterBottom component="p">
        {description}
      </Typography>
      <Button variant="outlined" color="primary" component={Link} to={link}>
        {linkText}
      </Button>
    </StyledPaperInner>
  </StyledPaper>
);

SimpleCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string
};

export default SimpleCard;
