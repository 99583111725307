import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { StyledBox } from './styles';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';

const ColouredBox = ({ number, link, title, color, iconColor, loading = false, size }) => {
  return (
    <StyledBox component={link ? Link : 'div'} to={link} color={color ? color : 'blue'}>
      <Typography component="h2" variant="h3">
        {loading ? <LoadingSpinner color={iconColor} size={size} /> : <strong>{number}</strong>}
      </Typography>
      <Typography component="span" variant="h6">
        {title}
      </Typography>
    </StyledBox>
  );
};

export default ColouredBox;
