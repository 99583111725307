import { styled } from '@mui/material/styles';
import Loader from 'react-loader-spinner';
import { yellow } from '@mui/material/colors';

export const StyledLoader = styled(Loader)`
  margin-bottom: -2px;
  position: relative;
  top: 2px;

  svg {
    rect {
      fill: ${(p) => (p.color ? p.color : yellow[500])};
    }
  }
`;
