import { NavLink, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { menuToggle } from 'reducers/menuReducer';
import { ListItemIcon, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { StyledDrawer, StyledList, StyledListItem, StyledListItemText } from './styles';
import { navigation, agencyNavigation, superNavigation } from './data';
import MenuToggleButton from 'components/Buttons/MenuToggle';
import AdminMenu from 'components/Menus/AdminMenu';
import Logo from 'components/Logo';
import { useEffect } from 'react';

const DashboardMenu = ({ location }) => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth);
  const { open } = useSelector((state) => state.menu);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => dispatch(menuToggle({ open: !open }));

  useEffect(() => {
    if (isMobile) {
      dispatch(menuToggle({ open: false }));
    } else {
      dispatch(menuToggle({ open: true }));
    }
  }, [isMobile, dispatch]);

  const pathChecker = (location, route) => {
    const matchProfile = matchPath(location.pathname, {
      path: route,
      exact: true
    });

    return Boolean(matchProfile);
  };

  return (
    <StyledDrawer open={open} onOpen={toggleDrawer} onClose={toggleDrawer} variant="permanent">
      <Logo />

      <StyledList component="nav" aria-label="Dashboard navigation">
        {navigation.map(({ route, icon, text, label, children }, index) =>
          open || (!open && !children) ? (
            <Tooltip title={!open ? label : ''} placement="right" key={index}>
              <StyledListItem
                button
                exact={route ? true : ''}
                component={route ? NavLink : null}
                isActive={(match, location) => pathChecker(location, route)}
                to={route}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <StyledListItemText primary={text} />
              </StyledListItem>
            </Tooltip>
          ) : null
        )}

        {(role === 'superAdmin' || role === 'agencyAdmin') && <AdminMenu navigation={agencyNavigation} />}

        {role === 'superAdmin' && <AdminMenu navigation={superNavigation} />}

        <MenuToggleButton open={open} handleDrawerToggle={toggleDrawer} />
      </StyledList>
    </StyledDrawer>
  );
};

export default DashboardMenu;
