import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { IconButton, TextField, Typography } from '@mui/material';

export const ForgotLink = styled(Link)`
  color: ${(p) => p.theme.palette.common.black};
  float: right;
  margin-bottom: ${(p) => p.theme.spacing(2)};
  text-decoration: none;
`;

export const IconButtonPosition = styled(IconButton)`
  position: absolute !important;
  right: 0;
  top: 22px;
`;

export const ErrorMessage = styled(Typography)`
  color: red;
  margin-top: ${(p) => p.theme.spacing(3)} !important;
`;

export const StyledField = styled(TextField)`
  color: ${(p) => p.theme.palette.common.black};

  .MuiFormLabel-root {
    color: ${(p) => p.theme.palette.common.black};

    &.Mui-error {
      color: red;
    }
  }

  .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => p.theme.palette.common.black} !important;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: ${(p) => p.theme.palette.common.black};
  }

  .MuiInputBase-root {
    color: ${(p) => p.theme.palette.common.black};

    &:before,
    &:after {
      border-color: ${(p) => p.theme.palette.common.black};
    }

    &.Mui-error {
      color: red;

      .MuiOutlinedInput-notchedOutline {
        border-color: red !important;
      }

      &:before,
      &:after {
        border-color: red;
      }
    }
  }
`;
