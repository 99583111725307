import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getGravatarUrl } from 'react-awesome-gravatar';
import { StyledAvatar } from './styles';

const AvatarGenerator = ({ email, size = 38, firstname = '', lastname = '', style }) => {
  const { lightMode } = useSelector((state) => state.type);

  let profileUrl = null;
  if (email) {
    profileUrl = getGravatarUrl(email, {
      size,
      default: '404'
    });
  }

  return (
    <StyledAvatar alt={`${firstname || ''} ${lastname || ''}`} src={profileUrl} style={style} lightMode={lightMode}>
      {firstname ? firstname.slice(0, 1).toUpperCase() : ''}
      {lastname ? lastname.slice(0, 1).toUpperCase() : ''}
    </StyledAvatar>
  );
};

AvatarGenerator.propTypes = {
  email: PropTypes.string,
  size: PropTypes.number,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  style: PropTypes.object
};

export default AvatarGenerator;
