import { styled } from '@mui/material/styles';

export const MaterialTableWrapper = styled('div')`
  .MuiPaper-root {
    border-radius: 8px !important;
    border: ${(p) => (p.border === 'false' ? 'none' : '1px solid inherit')} !important;
    padding: 0;
  }

  .MuiTableHead-root {
    .MuiTableCell-head {
      background: transparent;
      font-weight: bold;
    }

    .MuiTableCell-root:last-child {
      ${(p) => (p.lastColumnWide ? 'width: 140px !important' : '')}
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root:last-child {
      .MuiTableCell-root {
        border-bottom: none;
      }
    }
  }

  .MuiTableCell-footer {
    border-top: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none;
  }

  .MuiTablePagination-input {
    input {
      display: none;
    }
  }
`;
