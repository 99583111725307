import client from 'config/graphql';
import { authRef } from 'config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { history } from 'helpers/History';
import { GET_USER_QUERY } from './data';
import { setUser } from 'reducers/authReducer';
import { setLoginError } from 'reducers/errorReducer';
import { friendlyFirebaseMessage } from 'helpers/firebaseMessages';
import * as ROUTES from 'constants/routes';

export const doSignInWithEmailAndPassword = (email, password) => (dispatch) => {
  dispatch(setLoginError(false));

  signInWithEmailAndPassword(authRef, email, password)
    .then(async (result) => {
      /** get the user token */
      await authRef.currentUser
        .getIdToken(true)
        .then(async (idToken) => {
          /** take the token and  */
          await client
            .query({
              query: GET_USER_QUERY,
              context: {
                headers: {
                  authorization: idToken
                }
              }
            })
            .then(({ data: { user } }) => {
              /** On successful login fetch the user role */
              dispatch(
                setUser({
                  firstname: user.firstname,
                  lastname: user.lastname,
                  email: user.email,
                  verified: user.verified,
                  features: user.features,
                  role: user.role,
                  projectCount: user.projectCount,
                  agencies: user.agencies,
                  projects: user.projects,
                  support: user.support
                })
              );
              history.push(ROUTES.DASHBOARD);
            });
        })
        .catch(({ code }) => {
          dispatch(setLoginError(friendlyFirebaseMessage(code)));
        });
    })
    .catch(({ code }) => {
      dispatch(setLoginError(friendlyFirebaseMessage(code)));
    });
};
