import { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { doPasswordReset } from 'actions';
import { validationSchema } from './validation';
import { StyledField } from 'components/Forms/LoginForm/styles';
import * as ROUTES from 'constants/routes';

const PasswordForgetForm = () => {
  const dispatch = useDispatch();
  const lightMode = useSelector((state) => state.type.lightMode);
  const [submitBlocked, setSubmitBlocked] = useState(false);

  const submitComplete = ({ email }, { setSubmitting }) => {
    if (!submitBlocked) {
      setSubmitBlocked(true);
      setSubmitting(true);

      dispatch(doPasswordReset(email));
    }
  };

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => submitComplete(values, formikBag)}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <StyledField
            component={TextField}
            name="email"
            type="text"
            label="Email"
            autoComplete="email"
            margin="normal"
            required
            fullWidth
            disabled={false}
            style={{ marginBottom: '30px' }}
          />

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color={lightMode ? 'primary' : 'secondary'}
                fullWidth
              >
                Send reset email
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                component={Link}
                size="large"
                variant="outlined"
                color={lightMode ? 'primary' : 'secondary'}
                fullWidth
                to={ROUTES.AUTH_SIGN_IN}
              >
                Back to login
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordForgetForm;
