import moment from 'moment';
import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, Grid, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@apollo/client';
import { TIME_TRACKING } from './data';
import { formatDuration } from 'helpers/content';
import StaticIntro from 'components/StaticIntro';
import AvatarGenerator from 'components/AvatarGenerator';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import Tabs from 'components/Tabs';
import TimeTrackingTable from './TimeTrackingTable';

const TimeTrackingPage = () => {
  const { email: authEmail } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(0);
  const [startDate, setStartDate] = useState(moment().startOf('week').add(1, 'days').format('YYYY/MM/DD'));
  const [endDate, setEndDate] = useState(moment().endOf('week').add(1, 'days').format('YYYY/MM/DD'));
  const [isCustom, setIsCustom] = useState(false);
  const [selectedOption, setSelectedOption] = useState('thisWeek');

  const { loading, error, data, refetch } = useQuery(TIME_TRACKING, {
    variables: {
      startDate,
      endDate
    }
  });

  useEffect(() => {
    setDates('thisWeek');
  }, []);

  useEffect(() => {
    if (data && data.timeTracking) {
      const index = data.timeTracking.findIndex(({ user }) => user.email === authEmail);
      if (index !== -1) {
        setTab(index);
      }
    }
  }, [authEmail, data]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate, refetch]);

  const setDates = (option) => {
    let start, end;
    switch (option) {
      case 'thisWeek':
        start = moment().startOf('week').add(1, 'days').format('YYYY/MM/DD');
        end = moment().endOf('week').add(1, 'days').format('YYYY/MM/DD');
        break;
      case 'lastWeek':
        start = moment().subtract(1, 'weeks').startOf('week').add(1, 'days').format('YYYY/MM/DD');
        end = moment().subtract(1, 'weeks').endOf('week').add(1, 'days').format('YYYY/MM/DD');
        break;
      case 'thisMonth':
        start = moment().startOf('month').format('YYYY/MM/DD');
        end = moment().format('YYYY/MM/DD');
        break;
      case 'lastMonth':
        start = moment().subtract(1, 'months').startOf('month').format('YYYY/MM/DD');
        end = moment().subtract(1, 'months').endOf('month').format('YYYY/MM/DD');
        break;
      case 'today':
        start = moment().format('YYYY/MM/DD');
        end = moment().format('YYYY/MM/DD');
        break;
      default:
        start = moment().format('YYYY/MM/DD');
        end = moment().format('YYYY/MM/DD');
    }
    setStartDate(start);
    setEndDate(end);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleToggleChange = (event) => {
    setIsCustom(event.target.checked);
    if (!event.target.checked) {
      setDates(selectedOption);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setDates(event.target.value);
  };

  const Total = ({ totalSpentSeconds, workingTimeSeconds }) => (
    <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
      <Typography component="h5" variant="h5">
        <strong>Total</strong>
      </Typography>

      <Typography component="h5" variant="h5">
        <strong>
          {totalSpentSeconds === 0 ? '0' : formatDuration(totalSpentSeconds)} / {formatDuration(workingTimeSeconds)}
        </strong>
      </Typography>
    </Stack>
  );

  const { timeTracking } = data || {};

  return (
    <>
      <StaticIntro pretitle="Management" title="Time Tracking" />

      {loading && <LoadingCardWrapper page={true} />}
      {error && 'Currently unavailable'}
      {!loading && !error && (
        <>
          <Grid container spacing={2} sx={{ mb: 5 }}>
            <Grid item xs={12} md={8}>
              <Tabs
                tab={tab}
                tabs={timeTracking.map(({ user: { email, firstname, lastname } }) => (
                  <>
                    {firstname ? `${firstname} ${lastname}` : email}
                    <AvatarGenerator
                      email={email}
                      firstname={firstname}
                      lastname={lastname}
                      style={{ width: '16px', height: '16px', marginLeft: '8px' }}
                    />
                  </>
                ))}
                handleTabChange={handleTabChange}
              />
            </Grid>

            <Grid item xs={12} md={4} container justifyContent="flex-end">
              <Stack spacing={2} direction="row">
                {isCustom ? (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack spacing={2} direction="row">
                      <DatePicker
                        label="Start Date"
                        value={moment(startDate, 'YYYY/MM/DD')}
                        onChange={(date) => setStartDate(moment(date).format('YYYY/MM/DD'))}
                        shouldDisableDate={(date) => moment(date).day() === 0 || moment(date).day() === 6}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <DatePicker
                        label="End Date"
                        value={moment(endDate, 'YYYY/MM/DD')}
                        onChange={(date) => setEndDate(moment(date).format('YYYY/MM/DD'))}
                        shouldDisableDate={(date) => moment(date).day() === 0 || moment(date).day() === 6}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                ) : (
                  <Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="" disabled>
                      Select Date Range
                    </MenuItem>
                    <MenuItem value="thisWeek">This Week</MenuItem>
                    <MenuItem value="lastWeek">Last Week</MenuItem>
                    <MenuItem value="thisMonth">This Month</MenuItem>
                    <MenuItem value="lastMonth">Last Month</MenuItem>
                    <MenuItem value="today">Today</MenuItem>
                  </Select>
                )}
                <FormControlLabel
                  control={<Switch checked={isCustom} onChange={handleToggleChange} />}
                  label="Fine&nbsp;Tune"
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {timeTracking.map(({ user: { email }, worklogs, totalSpentSeconds, workingTimeSeconds }, index) =>
                tab === index ? (
                  <Fragment key={email}>
                    <Total totalSpentSeconds={totalSpentSeconds} workingTimeSeconds={workingTimeSeconds} />
                    <TimeTrackingTable worklogs={worklogs} startDate={startDate} endDate={endDate} />
                  </Fragment>
                ) : null
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default TimeTrackingPage;
