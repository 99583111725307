import { useQuery } from '@apollo/client';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { StyledDescription, StyledChip, TicketIntro } from './styles';
import { SUPPORT_TICKET_QUERY } from './data';
import { startCase } from 'helpers/content';
import AddCommentToTicket from 'components/Forms/AddCommentToTicket';
import AttachmentThumbs from 'components/Attachments/AttachmentThumbs';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import Comments from './Comments';
import * as ROUTES from 'constants/routes';

const TaskPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(SUPPORT_TICKET_QUERY, { variables: { id } });

  const ticket = data?.support?.ticket;

  if (loading) {
    return (
      <Grid item xs={12} md={6}>
        <LoadingCardWrapper />
      </Grid>
    );
  }

  if (error || ticket) {
    return (
      <>
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            {error && (
              <>
                <Typography component="h2" variant="h4" gutterBottom>
                  Unabled to view or access task
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                  If you believe this is incorrect please get in touch with us
                </Typography>
                <Button
                  component={Link}
                  to={`${ROUTES.DASHBOARD_SUPPORT}`}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                >
                  Go to tickets
                </Button>
              </>
            )}

            {ticket && (
              <>
                <TicketIntro>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<KeyboardBackspace />}
                    sx={{ mb: 2 }}
                    onClick={() => {
                      if (history.length > 2) {
                        history.goBack();
                      } else {
                        history.push(ROUTES.DASHBOARD);
                      }
                    }}
                  >
                    Go Back
                  </Button>

                  <Typography component="h6" variant="body2" color={grey[500]}>
                    {ticket.key}
                  </Typography>
                  <Typography component="h1" variant="h4" gutterBottom>
                    {ticket.fields.summary}
                  </Typography>
                  <Typography component="h4" variant="h6" gutterBottom>
                    <StyledChip
                      label={startCase(ticket.fields.status.name)}
                      variant="outlined"
                      open={ticket.fields.status.statusCategory.name !== 'Done' ? true : false}
                    />
                  </Typography>

                  <Grid container>
                    <Grid item xs={12}>
                      <StyledDescription
                        dangerouslySetInnerHTML={{
                          __html: ticket.fields.description
                        }}
                      />
                    </Grid>
                  </Grid>
                </TicketIntro>

                {ticket.fields.status.statusCategory.name !== 'Done' ? (
                  <>
                    <Typography component="h4" variant="h6">
                      Add a Response:
                    </Typography>
                    <AddCommentToTicket ticketId={ticket.id} />
                  </>
                ) : (
                  <TicketIntro>
                    <Typography component="h4" variant="h6" gutterBottom>
                      This ticket has been closed.
                    </Typography>
                    <Typography component="p" gutterBottom>
                      You can create a follow up ticket by clicking the button below.
                    </Typography>
                    <Button
                      component={Link}
                      to={`${ROUTES.DASHBOARD_SUPPORT_CREATE}?f=${ticket.id}`}
                      variant="contained"
                      color="primary"
                    >
                      Create Follow Up Ticket
                    </Button>
                  </TicketIntro>
                )}

                <AttachmentThumbs attachments={ticket.fields.attachment} removeFile={false} title="Attachments" />

                <Comments comments={ticket.fields.comment.comments} />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </>
    );
  }

  return null;
};

export default TaskPage;
