import { styled, css } from '@mui/material/styles';

export const StyledWrapper = styled(
  ({
    columns,
    logo,
    productOwnerStart,
    productOwnerEnd,
    projectStart,
    projectEnd,
    status,
    dateStart,
    dateEnd,
    taskChart1Start,
    taskChart1End,
    taskChart2Start,
    taskChart2End,
    overallChartStart,
    overallChartEnd,
    status1Start,
    status1End,
    status2Start,
    status2End,
    status3Start,
    status3End,
    ...other
  }) => <div {...other} />
)`
  ${css`
    margin-top: 60px;

    & #pouch-root {
      display: none !important;
    }

    .wrapper div:not(.rotateText) {
      padding: 5px;
      border: 1px solid rgba(255, 255, 255, 0.12);
      position: relative;
    }

    .wrapper {
      border: 0;
      display: grid;
      grid-template-columns: repeat(${(p) => p.columns}, 1fr);
      gap: 0;
      padding: 0;
    }

    & .titles {
      border: none;
    }

    .header {
      background-color: #444;
      color: #fff;
      border-color: #000;
    }

    .subheader {
      background-color: #555;
      color: #fff;
    }

    .logo {
      grid-row: 1 / 3;
      grid-column: 1 / ${(p) => p.logo};
    }

    .product_owner,
    .scrum,
    .releases,
    .release,
    .axis {
      grid-column: ${(p) => p.productOwnerStart} / ${(p) => p.productOwnerEnd};
    }

    .project,
    .vision,
    .schedule {
      grid-column: ${(p) => p.projectStart} / ${(p) => p.projectEnd};
    }

    .vision {
      grid-column: ${(p) => p.projectStart} / ${(p) => p.projectEnd} + ${(p) => p.status};
    }

    .vision h3 {
      margin-bottom: 4px;
    }

    .vision h4 {
      margin: 0 0 30px;
    }

    h2 {
      margin: 10px 0 20px;
    }

    .ticket-link {
      color: #999;
      cursor: pointer;
      font-size: 12px;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
    }

    .ticket-link:hover {
      color: #ffffff;
    }

    .client-to-provide {
      background: #d8a60d;
      color: #000000;
    }

    .client-to-provide a {
      color: #000000;
    }

    .client-to-provide a:hover {
      color: #4a4a4a;
    }

    .date,
    .status,
    .key {
      grid-column: ${(p) => p.dateStart} / ${(p) => p.dateEnd};
    }

    .task_chart_1 {
      grid-column: ${(p) => p.taskChart1Start} / ${(p) => p.taskChart1End};
    }

    .task_chart_2 {
      grid-column: ${(p) => p.taskChart2Start} / ${(p) => p.taskChart2End};
    }

    .overall_chart {
      grid-column: ${(p) => p.overallChartStart} / ${(p) => p.overallChartEnd};
    }

    .features {
      grid-column: 1 / ${(p) => p.logo};
    }

    .status_1 {
      grid-column: ${(p) => p.status1Start} / ${(p) => p.status1End};
    }

    .status_2 {
      grid-column: ${(p) => p.status2Start} / ${(p) => p.status2End};
    }

    .status_3 {
      grid-column: ${(p) => p.status3Start} / ${(p) => p.status3End};
    }

    .row_1 {
      grid-row: 1;
    }

    .row_2 {
      grid-row: 2;
    }

    .row_3 {
      grid-row: 3;
    }

    .row_4 {
      grid-row: 4;
    }

    .rotateText {
      width: 100%;
      height: 100%;
      display: flex;
      border: none;
    }

    .rotateText span {
      writing-mode: tb-rl;
      transform: rotate(180deg);
      padding-top: 5px;
    }

    .circle {
      width: 16px;
      position: absolute;
      display: inline-block;
      height: 16px;
      border-radius: 100%;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    .completed .circle {
      background: #333;
    }

    .todo .circle {
      background: transparent;
      border: 1px solid #999;
    }

    .has-feature .circle {
      background: #999999;
    }

    .wrapper .major_milestone {
      border: 2px solid red !important;
    }

    .key {
      border: none;
      font-size: 14px;
      padding: 4px;
    }

    .backlog {
      background: lightgray;
      color: #000000;
    }

    .in_progress {
      background: #3d3dc4;
    }

    .adequate {
      background: #0f9e0f;
    }

    .worrisome {
      background: #d8a60d;
      color: #000000;
    }

    .dangerous {
      background: red;
    }
  `}
`;
