import { styled } from '@mui/material/styles';
import { FormHelperText, Typography, IconButton } from '@mui/material';
import { red } from '@mui/material/colors';

export const SignInLink = styled(Typography)`
  color: ${(p) => p.theme.palette.common.black};
  display: inline-block;
  margin-top: ${(p) => p.theme.spacing(3)} !important;
`;

export const IconButtonPosition = styled(IconButton)`
  position: absolute !important;
  right: 0;
  top: 22px;
`;

export const CenterButton = styled('div')`
  margin-top: ${(p) => p.theme.spacing(2)};
  text-align: center;
`;

export const ErrorMessage = styled(Typography)`
  color: ${red[700]};
  margin-top: ${(p) => p.theme.spacing(3)} !important;
`;

export const StyledErrorHelperText = styled(FormHelperText)`
  color: ${red[700]};
  margin-left: 0;
  margin-right: 0;
  position: relative;
  top: -6px;
`;

export const StyledLabel = styled('span')`
  color: inherit;
`;
