export const dueThisWeek = (i, task) => {
  if (typeof task.week_due !== 'boolean' && Number(task.week_due) === i) {
    const status = task.status === 'Done' ? 'completed' : 'todo';
    return {
      class: task.type === 'major_milestone' ? `${status} major_milestone` : status,
      milestone: task.type === 'major_milestone' ? 'Major Milestone' : ''
    };
  }
  return {
    class: '',
    milestone: ''
  };
};

export const isTaskInFeature = (feature, task) => {
  if (task.components.includes(feature.id)) {
    return 'has-feature';
  }
  return false;
};
