import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { StyledIconButton } from './styles';

const MenuToggleButton = ({ open, handleDrawerToggle }) =>
  open ? (
    <StyledIconButton onClick={handleDrawerToggle} color="primary">
      <ChevronLeft />
    </StyledIconButton>
  ) : (
    <StyledIconButton onClick={handleDrawerToggle} color="primary">
      <ChevronRight />
    </StyledIconButton>
  );

export default MenuToggleButton;
