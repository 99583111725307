import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { LIST_ALL_USERS_FROM_AGENCIES_QUERY } from './data';
import Table from 'components/Table';
import StaticIntro from 'components/StaticIntro';
import AvatarGenerator from 'components/AvatarGenerator';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import * as ROUTES from 'constants/routes';

const createTablePagination = (number = false, value = 20) => {
  if (!number) return;

  let pageSize = Math.min(number, value);

  let pageSizeOptions = [5, 10, 20];
  if (!pageSizeOptions.includes(number)) {
    pageSizeOptions.push(number);
  }

  return {
    pageSize,
    pageSizeOptions
  };
};

const UsersPage = () => {
  const { loading, error, data } = useQuery(LIST_ALL_USERS_FROM_AGENCIES_QUERY);

  const { agencies } = data || {};

  return (
    <>
      <StaticIntro pretitle="Manager Team Members" title="Manage your companies team members">
        Invite or remove your team members
      </StaticIntro>

      {loading && <LoadingCardWrapper page={true} />}
      {error && 'Currently unavailable'}
      {!loading && !error && agencies && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={2}></Grid>

          <Grid item xs={12} md={8}>
            {agencies.map(({ name, users }, index) => (
              <Box mb={5} key={index}>
                <Table
                  title={name}
                  columns={[
                    { title: '', field: 'avatar', align: 'left', width: 40 },
                    { title: 'Name', field: 'name', align: 'left', width: 180 },
                    { title: 'Email', field: 'email', align: 'left' },
                    { title: '', field: 'userProfile', align: 'right' }
                  ]}
                  data={
                    users &&
                    users.map(({ uid, firstname, lastname, email }, index) => ({
                      avatar: <AvatarGenerator email={email} size={30} firstname={firstname} lastname={lastname} />,
                      name: `${firstname} ${lastname}`,
                      email,
                      userProfile: (
                        <Button
                          component={Link}
                          variant="outlined"
                          size="small"
                          to={`${ROUTES.DASHBOARD_USER_PROFILE_ROOT}/${uid}`}
                        >
                          View
                        </Button>
                      )
                    }))
                  }
                  options={{
                    sorting: true,
                    draggable: false,
                    header: false,
                    ...createTablePagination(users.length)
                  }}
                  style={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0
                  }}
                />
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} md={2}></Grid>
        </Grid>
      )}
    </>
  );
};

export default UsersPage;
